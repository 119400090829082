.home-sponsor{
  @include flex-columns(5);
  .column .pageEl:first-of-type{
    min-width: 100%;
  }
  .drag:first-of-type{
    min-width: 100%;
  }
  .user_mode & .column > div{
    flex: 1 1 auto;
  }

  .heroPhotoElement{
    margin-right: 5px !important;
    margin-left: 5px !important;
    img{
      width: auto !important;
      display: block;
      max-height: 60px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
