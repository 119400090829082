// Navigation - Desktop
// First Level
// Second Level
// Third Level
// ——————————————————————————————————————————
// First Level

#topNav {
    #siteContainer2.one_panel &,
    & {
        background-color: $primary-color;
    }
    border-bottom: 3px solid $secondary-color;

    .nav {
        @extend %standard-width;
        display: flex;
        flex-flow: row;
        align-items: stretch;
        justify-content: space-between;
        list-style: none;
        min-height: 80px;
    }

    .nav-left,
    .nav-right {
        width: 50%;
    }

    .nav-mid {
        width: 150px;
        min-width: 150px;
        position: relative;

        a {
            background: url("../images/site-logo-bg.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 150px;
            height: 138px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            z-index: 15;
        }
    }

    .split-nav {
        display: flex;
        flex-flow: row;
        align-items: stretch;
        justify-content: space-between;

        li,
        ul {
            list-style: none;
        }

        > li {
            display: flex;
            flex: 1 1 auto;
            align-items: stretch;
            justify-content: center;
            position: relative;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                // text-decoration: none;
                // &:hover{text-decoration: none;}
                padding: 5px 3px;
                color: $white-color;
                font-family: $secondary-font;
                text-transform: uppercase;
                font-size: 20px;
                @include respond-to(max, 840px) {
                    font-size: 18px;
                }
                line-height: 1;
                @include font-smooth;
                position: relative;
                z-index: 2;
            }

            > a {
                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    transition: height .2s linear;
                    padding: 0;
                    height: 0;
                    background-color: $secondary-color;
                    width: 100%;
                    z-index: 5;
                }

                &:before {
                    top: 100%;
                    transform: translateY(0px);
                }

                &:after {
                    bottom: -3px;
                    transform: translateY(0px);
                }
            }

            &.hover,
            &.selected,
            &:hover {
                > a {
                    // background-color: $dark-color;
                    // color: #ffcc00;
                    position: relative;

                    &:after,
                    &:before {
                        height: 7px;
                    }
                }
            }

            &.selected {
                > a {
                    // background-color: $dark-color;
                    // color: $secondary-color;
                }
            }

            &.disabled {
                > a {
                    // background-color: darken($primary-color, 5%);
                    // color: #000;
                }
            }

            &:hover .dropdown {
                display: block;

                li:hover ul {
                    display: block;
                }
            }
            z-index: 99;

            ul {
                box-shadow: 0 5px 5px 0 rgba(#000, .5);

                ul {
                    transform: translateX(5px);
                    min-width: 150px;
                    background: rgba(34, 34, 34, 0.9);
                    top: 0;
                    left: 100%;

                    &.right {
                        right: 100%;
                        left: auto;
                        transform: translateX(-5px);
                    }
                }

                &.dropdown {
                    li {
                        position: relative;
                    }

                    a {
                        font-size: 14px;
                        text-align: left;
                    }
                    // border-top: 5px solid transparent;
                    // transform: translateY(5px);
                    transform: translateY(3px) translateX(-50%);
                    left: 50%;
                    min-width: 180px;
                    background: rgba(#222, .9);
                    z-index: -1;

                    &.right {
                        right: 0;
                        left: auto;
                        transform: translateY(3px);

                        .dropdown-disclosure {
                            left: 0;
                            transform: rotate(180deg);
                        }
                    }
                }

                li {
                    // margin: 0px 5px;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;

                    + li {
                        border-top: 1px solid #666;
                    }

                    a {
                        padding: 10px;
                        color: #FFF;
                        transition: padding .2s linear;

                        &:hover {
                            // padding: 10px 4px 10px 16px;
                        }
                    }

                    &.hover > a,
                    &:hover > a {
                        padding: 10px 4px 10px 16px;
                        // color: $secondary-color;
                    }

                    a.disabled {
                        background: rgba(255,255,255,.2);
                        color: #AAA;
                    }
                    // &:hover > a, &.hover > a, a{
                    //   background: $primary-color;
                    // }
                }
            }
        }
    }

    .dropdown,
    .dropdown ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
    }

    a {
        text-decoration: none;
    }
}
// Hide Assets link in User Mode
body.user_mode #nav_id-page_node_2249523 {
    display: none;
}
// #topNav{
//   table, tbody, tr, td{
//     display: block;
//   }
//   tr{
//     display: flex;
//     flex-flow: row nowrap;
//     align-items:stretch;
//     justify-content: space-between;
//
//     // border: 1px solid tomato;
//     // padding: 2px;
//     min-height: 80px;
//   }
//   td{
//     display: flex;
//     flex: 1 1 auto;
//     align-items: stretch;
//     justify-content: center;
//
//     // border: 1px solid mediumslateblue;
//     // padding: 2px;
//   }
//   a{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 100%;
//     text-decoration: none;
//     &:hover{text-decoration: none;}
//
//     // border: 1px solid mediumseagreen;
//     // padding: 2px;
//   }
//
// }
// // ——————————————————————————————————————————
// // Second Level
// #topNav{
//   td{
//     ul{
//       box-shadow: none;
//       top: 100%;
//       left: 0;
//       li{
//         a{
//           background: none;
//           border: none;
//           width: auto;
//           padding: 0;
//         }
//         &:hover, &.hover{
//           background-color: transparent;
//           > a{
//             background: none;
//             border: none;
//           }
//         }
//       }
//     }
//   }
// }
// // ——————————————————————————————————————————
// // Third Level
// #topNav{
//   ul ul{
//     border: none;
//     top: 0;
//     left: 100%;
//   }
// }
//
//
// // Theme Style
// #topNav{
//   &,#siteContainer2.one_panel & {background-color: $primary-color;}
//   border-bottom: 3px solid $secondary-color;
//   tbody{
//     @extend %standard-width;
//   }
//   // First Level
//   td{
//     > a{
//       padding: 5px 3px;
//       color: $white-color;
//       font-family: $secondary-font;
//       text-transform: uppercase;
//       font-size: 20px;
//       -webkit-font-smoothing: antialiased;
//       &:after, &:before{
//         content: "";
//         position: absolute;
//         left: 0;
//         transition: height .2s linear;
//         padding: 0;
//         height: 0px;
//         background-color: $secondary-color;
//         width: 100%;
//       }
//       &:before{
//         top: 100%;
//         transform: translateY(3px);
//       }
//       &:after{
//         bottom: 0;
//         transform: translateY(0px);
//       }
//     }
//     &:hover, &.hover, &.selected{
//       > a{
//         // background-color: $dark-color;
//         // color: #ffcc00;
//         position: relative;
//         &:before, &:after{
//           height: 4px;
//         }
//       }
//     }
//     &.selected{
//       > a{
//         // background-color: $dark-color;
//         // color: $secondary-color;
//       }
//     }
//     &.disabled{
//       > a{
//         // background-color: darken($primary-color, 5%);
//         // color: #000;
//       }
//     }
//   }
//
//     // Second Level
//   td{
//     z-index: 1;
//     ul{
//       box-shadow: 0px 5px 5px 0px rgba(#000, .5);
//       ul{
//         transform: translateX(5px);
//         min-width: 150px;
//         background: rgba(34, 34, 34, 0.9);
//         &.right{
//           right: 100%;
//           left: auto;
//           transform: translateX(-5px);
//         }
//       }
//       &.dropdown{
//         // border-top: 5px solid transparent;
//         // transform: translateY(5px);
//         transform: translateY(3px) translateX(-50%);
//         left: 50%;
//         min-width: 180px;
//         background: rgba(#222, .9);
//         z-index: -1;
//         &.right{
//           right: 0;
//           left: auto;
//           transform: translateY(3px);
//           .dropdown-disclosure{
//             left: 0;
//             transform: rotate(180deg);
//           }
//         }
//       }
//       li{
//         margin: 0px 5px;
//         + li {border-top: 1px solid #666;}
//         a{
//           padding: 10px 10px;
//           color: #FFF;
//           transition: padding .2s linear;
//           &:hover{
//             // padding: 10px 4px 10px 16px;
//           }
//         }
//         &:hover > a, &.hover > a{
//           padding: 10px 4px 10px 16px;
//           // color: $secondary-color;
//         }
//         a.disabled{
//           background: rgba(255,255,255,.2);
//           color: #AAA;
//         }
//         // &:hover > a, &.hover > a, a{
//         //   background: $primary-color;
//         // }
//       }
//     }
//   }
// }
// // #topNav{
// //   min-height: 200px;
// //   // Typography
// //   a{
// //     line-height: 1.2;
// //   }
// //   tbody{
// //     background-color: $primary-color;
// //     padding: 15px;
// //     max-width: 980px;
// //     margin: 0 0 0 auto;
// //     position: relative;
// //   }
// //   // First Level
// //   td{
// //     > a{
// //       padding: 5px 3px;
// //       color: $white-color;
// //     }
// //     &:hover, &.hover{
// //       > a{
// //         // background-color: $dark-color;
// //         color: #ffcc00;
// //       }
// //     }
// //     &.selected{
// //       > a{
// //         background-color: $dark-color;
// //         color: $secondary-color;
// //       }
// //     }
// //     &.disabled{
// //       > a{
// //         background-color: darken($primary-color, 5%);
// //         color: #000;
// //       }
// //     }
// //   }
// //
// //   // Second Level
// //   td{
// //     ul{
// //       box-shadow: 0px 0px 5px 0px rgba(#000, .5);
// //       &.dropdown{
// //         // border-top: 5px solid transparent;
// //       }
// //       li{
// //         + li {border-top: 1px solid #000;}
// //         a{
// //           padding: 10px 15px;
// //           min-width: 120px;
// //           color: #FFF;
// //           background: #222;
// //         }
// //         &:hover > a, &.hover > a{
// //           background: $primary-color;
// //           color: $secondary-color;
// //         }
// //         a.disabled{
// //           background: #666;
// //           color: #000;
// //         }
// //         // &:hover > a, &.hover > a, a{
// //         //   background: $primary-color;
// //         // }
// //       }
// //     }
// //   }
// // }
//
//
//
// // SITE HEADER
// // .siteContainer{
// //   &:before{
// //     content:'';
// //     display:block;
// //     width:100vw;
// //     position: absolute;
// //     top:0;
// //     left: 50%;
// //     margin-left: -50vw;
// //
// //     height: 152px;
// //     background: url(../images/chalkboard-bg.png) top left repeat;
// //     margin-top: -20px;
// //     padding: 10px 0 0 0;
// //     transform: rotate(1deg);
// //     box-shadow: inset 0px -40px 0px -30px rgba(0, 0, 0, 0.5);
// //   }
// // }
// // .site-header{
// //   position: absolute;
// //   top: 0;
// //   left:0;
// //   display: block;
// //   width: 100%;
// //   max-width: 262px;
// //   height:110px;
// //   z-index: 99;
// //   margin-left: 10px;
// //   a{
// //     display: block;
// //     width: 100%;
// //     width: 100%;
// //     height:110px;
// //     background: url(../images/ia-logo-3-1.png);
// //     background-position: left;
// //     background-repeat: no-repeat;
// //     background-size: contain;
// //   }
// // }
// //
// //
// // @media screen and(max-width:1270px){
// //   #topNav{
// //     tbody{
// //       max-width:100%;
// //     }
// //   }
// //   .site-header{
// //     top: 60px;
// //
// //   }
// //   .siteContainer:before{
// //     height: 200px;
// //     transform: rotate(0deg);
// //   }
// // }
