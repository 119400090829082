#accountNav{
  background: $primary-color;
  border-bottom: 1px solid $tertiary-color;
  a{
    border: none;
  }
  .nginLogo a{
    background: url(../images/sportngin-logo.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .currentSite a#edit_membership{
    border: none;
  }
}
