#siteFooter{
  background: linear-gradient(
      rgba( $primary-color, 1),
      rgba( $primary-color, 1)
    ), url(../images/field.jpg);
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 2px solid $secondary-color;
  padding: 15px;
  .logged_in &{
    padding-bottom: 45px;
  }
  ul{
    &:last-of-type{
      @extend %standard-width;
      font-size: 12px;
      color: #FFF;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-top: 15px;
      border-top: 1px solid #888;
      li{
        margin-right: 10px;
        &#poweredByNGIN{
          margin-right: 0;
          margin-left: auto;
        }
        a{
          color: #FFF;
          &:hover{
            color: $secondary-color;
          }
        }
      }
    }
  }
}
.main-footer{
  margin-bottom: 15px;
  @include font-smooth;
  .footer{
    padding: 0;
    @extend %standard-width;
  }
  color: #FFF;
  font-family: $secondary-font;
  .column{
    display: flex;
    flex-flow: row;
    @include respond-to(max, 820px){
      flex-wrap: wrap;
    }
    justify-content: space-between;
    &:before{
      content: "";
      display: block;
      position: relative;
      width: 100%;
      @include respond-to(max, 820px){
        max-width: 100%;
        margin-bottom: 15px;
      }
      max-width: 150px;
      height: 105px;
      background: url(../images/site-logo.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

  }
  .text p{
    font-size: 14px;
  }
  .pageElement h3 span{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 2px solid $tertiary-color;
    padding-bottom: 3px;
  }
  .contact-us, .stay-connected, .quick-links{
    width: 30%;
    @include respond-to(max, 540px){
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
      .textBlockElement h3{text-align: center !important;}
    }
    .pageElement{
      margin: 0;
    }
  }
  .contact-us{
    text-align: left;
  }
  .stay-connected{
    text-align: center;
    &:before{
      content:"Stay Connected";
      display: inline-block;
      position: relative;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      border-bottom: 2px solid $tertiary-color;
      padding-bottom: 3px;
      margin-bottom: 9px;

    }
    .sn-social-media-list .sn-social-media-icon{
      border-radius: 0;
      background-color: $secondary-color;
      transition: background-color .2s linear;
      &:hover{
        opacity: 1;
        background-color: darken($secondary-color, 10%);
      }
    }
  }
  .quick-links{
    text-align: right;
    .textBlockElement h3{text-align: right !important;}
  }
  .link-list{
    a{
      text-decoration: none;
      color: #FFF;
      margin-left: 10px;
      &:hover{
        color: $tertiary-color;
      }
    }
  }
}


.main-footer{
  .contact-us, .stay-connected, .quick-links{
    @include respond-to(max, 540px){
      width: 100%;
      margin: 15px 0;
      text-align: center;
      .textBlockElement h3{text-align: center !important;}
    }
  }
}
