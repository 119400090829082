html, body {
  font-family: $primary-font;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  color: $dark-color;
}
body{
  font-size: 100%;
}

%base-link{
  color: $primary-color;
  text-decoration: none;
  transition: color .2s linear;
  &:hover { color: darken($primary-color, 20%); }
}

// .textBlockElement p a {
//   @extend %base-link;
// }


.pageElement .text,
.pageElement .previewText,
.pageElement .newsText{
  // 1. Text
  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  // 2. Headers
  h1, h2, h3, h4, h5, h6{
    font-family: $secondary-font;
    font-weight: 500;
    line-height: 1.125;
    color: $dark-color;
  }
  h1{font-size: rem(38px);}
  h2{font-size: rem(32px);}
  h3{font-size: rem(24px); text-transform: uppercase;}
  h4{font-size: rem(18px); text-transform: uppercase;}
  h5{font-size: rem(16px); text-transform: uppercase;}
  h6{font-size: rem(16px);}

  // 3. Lists
  ul, ol {
    margin-left: 10px; //Removed from global scope for theme 2. Should become standard going forward.
    // margin-bottom: 1em; //Removed from global scope for theme 2. Should become standard going forward.
  }
  ul{list-style-type: disc;}
  // @mixin list-unstyled {
  //   margin-left: 0;
  //   list-style: none;
  // }
  li{
    margin: 0;
    line-height: 1.5;
    ul, ol{
      // margin-top: .5em;
    }
  }
  // 1. Anchors
  a {
    color: $secondary-color;
    text-decoration: none;
    transition: color .2s linear;

    position: relative;
    display: inline-block;

    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: $primary-color;
      opacity: 0;
      transform: translateY(6px);
      transition: transform 0.3s, opacity 0.3s;
      pointer-events: none;
    }

    &:hover, &:focus {
      color: $secondary-color;
      &:after{
        opacity: 1;
        transform: translateY(1px);
      }
    }
  }


  // 3. Inline mods
  b, strong { font-weight: bold; }
  i, em { font-style: italic; }
  small { font-size: .75em; }
  mark { background-color: yellow; }
  s, strike, del { text-decoration: line-through; }
  u { text-decoration: underline; }
  code{
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
  }

  // 4. Code
  code, kbd, pre, samp { font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }
  pre {
    display: block;
    padding: $base-padding;
    margin: 0 0 1rem 0;
    font-size: 90%;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  // 5. Block mods
  blockquote {
    font-family: $secondary-font;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.35;
    // float: right;
    color: $primary-color;
    padding: 0 0 0 10px;
    margin: 10px;
    &:before{
      content: "“";
      display: inline-block;
      font-weight: 900;
      color: #CCC;
    }
    &:after{
      content: "”";
      display: inline-block;
      font-weight: 900;
      color: #CCC;
    }
    p{
      display: inline;
      line-height: 1.35;
      font-size: 18px;
    } // Revisit when liquid
  }





  >{
    p, h1, h2, h3, h4, h5, h6, ol, ul, blockquote, pre{
      &:last-child{margin-bottom: 0;}
    }
  }
  ul, ol{
    margin-left: 10px;
    margin-bottom: 1em;
    li{
      margin-left: 10px;
      > ul, > ol{
        margin-bottom: 0;
      }
    }
  }
  table{
    border-color: #999;
    th, td{
      padding: 2px 12px;
    }
    margin-bottom: 1rem;

    &:not([border]){
      border-style: none;
      border-spacing: 2px;
      // border-collapse: separate;
      border-collapse: collapse;
      td {
        // border-color: rgba(102,96,102,0.5);
        //
        font-size: 12px;
        padding: 2px 12px;
        height: 30px;
        // background: $light-color;
      }
      th {
          // border-top: 0.0625rem solid $dark-color;
          // padding-bottom: 1rem;
          // font-weight: 700;
          //
          height: 30px;
          padding: 3px 12px;
          background: $primary-color;
          white-space: nowrap;
          color: #fff;
          text-transform: uppercase;
          line-height: 1.1;
          font: 600 12px/1.2 $secondary-font;

      }
      td, th {
        // border-top: 1px solid $light-color;
        // padding-right: 1rem;
        // padding-bottom: 0.5rem;
        // vertical-align: top;
        // text-align: left;
        // white-space: nowrap;
        // overflow-wrap: break-word;
        border: 1px solid $light-color;

      }
    }
  }
  img{
    max-width: 100%;
  }
}


// 2. Tables
.tableWrapper{
  table{
    font-size: 16px;
    font-family: $secondary-font;
  }
}
.tableElement {
  .odd{
    background-color: transparent;
  }
  tbody tr{
    border-bottom: 2px solid $dark-color;
  }
  table {
    tr {
      th {
        background: #333;
        color: #FFF;
        text-transform: uppercase;
        font-weight: 300;
        @include font-smooth();
      }
    }
  }
  .ajax_sort:hover {
    color: $primary-color;
  }
}
.dataTable{
  td, th{
    padding: 10px;
  }
}


// Headers
%header-2{
  font-family: $secondary-font;
  // @include font-smooth();
}

// .textBlockElement h3{
//   @extend %header-2;
//   font-size: rem(36px);
//   @include respond-to(max, 400px){ font-size: rem(26px); }
//   font-weight: 400;
//   line-height: 1;
//   color: $primary-color;
// }
.table-wrap{
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
}
