$aggregator-item-padding: 5px;
$c-even: #f3f3f3;
$c-odd: #fafafa;
$c-img: 95px;

.newsAggregatorElement{
  a{
    text-decoration: none;
  }
  .aggHeader{
    @extend %agg-headers;
  }
  .item{
    line-height: 1.2;
    border: none;
    // border-bottom: 2px solid #EEE;
    padding: 0;
    + .item{
      padding-top: $aggregator-item-padding;
    }
    &.odd{
      background-color: transparent;
    }

    &.condensed{
      position: relative;
      display: flex;
      align-items: flex-end;
      .expandedView{
        display: none;
      }
    }
    &.condensed > a{
      display: block;
      width: $c-img !important;
      position: absolute;
      overflow: hidden;
      img{
        // display: none;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translateX(-50%) translateY(-50%);
        // width: 100%;
      }
    }
  }
  .commentsAndMore{
    display: none;
  }
}
.newsAggregatorElement{
  .condensed{
    h4{
      font-family: $primary-font;
      font-size: rem(18px);
      line-height: 1.2;
      a{
        color: $dark-color;
        &:hover{color: $primary-color;}
      }
    }
    .dateAuthor{
      font-family: $primary-font;
      font-size: rem(11px);
      font-style: italic;
      line-height: 1;
      padding-top: 5px;
    }
  }
  .expanded{
    .newsItemElement{
      h2{
        font-family: $primary-font;
        font-size: rem(18px);
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        a{
          color: $dark-color;
          &:hover{color: $primary-color;}
        }
      }
    }
    .newsDate, .newsAuthor{
      font-family: $primary-font;
      font-size: rem(11px);
      font-style: italic;
      line-height: 1;
      padding-top: 5px;
    }
  }
}
// with image - condensed
.newsAggregatorElement{
  .details{
    margin-left: $c-img + 5px;
    padding: 10px;
    width: 100%;
    min-height: 95px;
  }
  .odd .details{background: $c-odd;}
  .even .details{background: $c-even;}
  a img{
    width: $c-img !important;
  }
}

.aggregatorElement .extendedOptions{
  font-family: $primary-font;
  font-size: rem(14px);
  margin: 0;
  padding: $aggregator-item-padding 0;
  border: none !important;
  background-color: transparent;
  a{ text-decoration: none;}
  .icon{
    padding: 0;
    background: none;
    padding: 0 2px;
    color: $light-color;
    transition: .2s linear;

    &:hover{
      color: $primary-color;
    }
  }
}



//


@mixin sm-news-aggregator{
  // .eventAggregatorElement{
  .item.condensed{
    flex-wrap: wrap;
    padding: 10px 5px;
    + .item{margin-top: 5px;}
    &.odd {background: $c-odd;}
    &.even {background: $c-even;}
  }
  .item.condensed > a{
    position: relative;
    width: 100% !important;
  }
  a img{
    float: none;
    width: 75px !important;
    display: block;
  }
  .condensed a img{margin: 0 auto;}
  .details{
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }
  // }
}



.newsAggregatorElement{
  .column &{
    @include respond-to(max, 290px){
      @include sm-news-aggregator;
    }
  }
  .pe33 &{
    @media screen and (min-width: 651px) and (max-width: 770px){
      @include sm-news-aggregator;
    }
  }
  .threeColumn255025 .pe25 &{
    @include respond-to(min, 651px){
      @include sm-news-aggregator;
    }
  }
  .threeColumn502525 .pe25 &, .threeColumn252550 .pe25 &{
    @media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px){
      @include sm-news-aggregator;
    }
  }
  // .twoColumn5050 .pe50 &{
  //   @media screen and (min-width: 576px) and (max-width: 875px){
  //     @include sm-news-aggregator;
  //   }
  // }
}
