#panelOne{
  padding: 30px 15px 0 15px;
  .subNav{
    font-size: 14px;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 2px solid $tertiary-color;
    // display: flex;
    // flex-flow: row wrap;
    // align-items: center;
    h4{
      font-family: $secondary-font;
      font-weight: 400;
      // text-transform: uppercase;
      // letter-spacing: 1px;
      font-size: 1em;
      display: inline-block;
      display: none;
      padding-top:1px;

      &:after{
        content: "\0399";
        display: inline-block;
        margin: 0 10px;
        // transform: translateY(-1px);
        font-family: $primary-font;
        font-weight: 100;
      }
    }
    .parents{
      display:flex;
      flex-flow: row wrap;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 1em;

      // text-transform: uppercase;
      // letter-spacing: 1px;
      // font-family: $quaternary-font;

      li{
        .user_mode & + li{
          margin-left: 10px;
          &:before{
            content: "\203A";
            color: $light-color;
            display: inline-block;
            margin-right: 10px;
            transform: translateY(-1px);
            font-family: $primary-font;
            font-weight: 100;
          }
          &.selected a{
            color: $secondary-color;
          }
        }
        a{
          display: inline-block;
          color: $light-color;
          transition: color .2s linear;
          padding: 5px 0;
          font-size: inherit;
          &:hover{
            color: $primary-color;
          }
        }
      }
      .cmsNavButton {
        margin-left: 10px;
      }
    }
    .children{
      margin-top: 1em;
      .user_mode &{
        display: none;
      }
    }
  }

}
