#siteHeader h1:empty,
#siteMiniHeader h1:empty {
    display: none;
}

#accountNav{
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // z-index: 9997;
  // background-color: #DDD;

  a{box-sizing: content-box;}
}
#siteHeader .adminToggle{
  position: fixed;
  top: auto;
  bottom: 0;
  right: 0;
  z-index: 10000;
}
#siteHeader #adminNav{
  position: fixed;
  top: auto;
  bottom: 0;
  right: 80px;
  z-index: 10000;
  a{
    margin: 0;
    line-height: 1;
  }
}



// User/Edit UI
#siteHeader .adminToggle, #siteHeader #adminNav{
  top: initial;
  left: initial;
  position: fixed;
  z-index:9998;
}
#siteHeader #adminNav{
  right: 3px;
  bottom: 5px;
  a{margin:0}
}
#siteHeader .adminToggle{
  right: 145px;
  bottom: 6px;
}


#siteHeader #adminNav{
  height: 40px;
  right:0;
  bottom:0;
  a{
    text-decoration: none;
    display: block;
    overflow: hidden;
    height: 40px;
    color: #808F98;
    transition: .2s ease-out;
    &:hover{
      color: #2598C6;
    }
  }
  > a:before {
    text-align: left;
    width: 100%;
    font-size: 10px;
    line-height: initial;
    font-weight: 900;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    display: block;
    position: relative;
    padding: 0px 0px 0px 60px;
    top: 50%;
    transform: translateY(-50%);
  }
  img{
    display: none;
  }

}
.user_mode #adminNav {
  background: url("../images/switch_off.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
  > a:last-of-type:before {
    content: 'Edit Mode';
  }
}
.edit_mode #adminNav {
  background: url("../images/switch_on.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
  > a:before {
    content: 'User Mode';
  }
}

#siteHeader .adminToggle{
  background: url("../images/admin_gear.png") 50% 50% no-repeat;
  height: 40px;
  width: 40px;
  right: 138px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
}
#siteHeader #adminNav .admin-trash{
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 178px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;

  &:before{
    display: none !important;
  }
}
#siteHeader #adminNav .admin-site-guide{
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 232px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;

  &:before{
    display: none !important;
  }

}

.row-1{
  padding:0;
  border-bottom: 5px solid $primary-color;
  .pageElement{
    margin: 0;
  }
}
@include respond-to(max, md){
  #accountNav, #adminNav, .adminToggle{
    display: none;
  }
}
