$table-cell-spacing: 1px;
.aggregatorElement{
  table{
    font-family: $primary-font;
    font-size: 12px;
  }
  .eventPreview{
    p{
      font-family: $primary-font;
      font-size: 12px;
    }
  }
}

// 4. Five Day View
.aggregatorElement .eventPreview {
  text-align: left;
  width: 100%;
  margin: 0;
  th {
    background: #DDD;
    word-break: break-word;
    text-align: center;
    line-height: 1;
    a {
      color: $primary-color-dk;
      font-weight: 600;
    }
  }
  td {
    padding: 10px;
    background-image: none;
    background: transparent;
    word-break: break-word;
    &:before {
      content: attr(data-week-view-date)'';
      display: none;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 10px;
      border-bottom: $table-cell-spacing solid $light-color;
      // border-bottom: $table-cell-spacing solid $light-color;
      background: #DDD;
      color: $primary-color-dk;
      font-family: $primary-font;
      font-size: 1em;
      line-height: 1;
      font-weight: 600;
    }
    a{
      font-family: $primary-font;
    }
  }
  td,
  th {
    border: none;
    border: $table-cell-spacing solid $light-color;
    // border-bottom: $table-cell-spacing solid $light-color;
    // &:first-child {
    //   border-left: 0;
    // }
    // &:last-child {
    //   border-right: 0;
    // }
  }
  .event + .event {
    padding-top: 10px;
  }
  .noentry {
    word-break:normal;
  }
}
.noEntry{
  border: none;
  // background-color: transparent;
  font-size: 12px;
}
.aggregatorElement .eventPreview .event{
  border: none;
  padding: 0;
}


@mixin sm-event-aggregator-five{
    &,
    tbody,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: 0;
      padding: 0;
      margin-bottom: $table-cell-spacing;
      word-break: word-break;
      &:before {
        display: block;
      }
    }
    .event {
      padding: 10px;
      & + .event {
        padding-top: 0;
      }
    }
    tbody{
      border-bottom: $table-cell-spacing solid $light-color;
    }
}


.aggregatorElement .eventPreview {
  .column &{
    @include respond-to(max, 450px){
      @include sm-event-aggregator-five;
    }
  }
  .pe33 &{
    @media screen and (min-width: 651px){
      @include sm-event-aggregator-five;
    }
  }
  .threeColumn255025 .pe25 &{
    @include respond-to(min, 651px){
      @include sm-event-aggregator-five;
    }
  }
  .threeColumn502525 .pe25 &, .threeColumn252550 .pe25 &{
    @media screen and (min-width: 451px){
      @include sm-event-aggregator-five;
    }
  }
  .threeColumn255025 .pe50 &, .twoColumn5050 .pe50 &{
    @media screen and (min-width: 651px) and (max-width: 850px){
      @include sm-event-aggregator-five;
    }
  }
  // .twoColumn5050 .pe50 &{
  //   @media screen and (min-width: 576px) and (max-width: 850px){
  //     @include sm-event-aggregator-five;
  //   }
  // }
}
