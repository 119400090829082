.pageEl:not(.link-callout) .linkElement{
  h4{
    a{
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      text-decoration: none;
      background: 0;
      padding: 6px 10px;
      background-color: $secondary-color;
      color: #FFF;
      &:hover{
        background-color: $primary-color;
        color: #FFF;
      }
      &:after{
        content:" \203A";
      }
    }
  }
  .text{
    margin: 0 0 10px 0;
    padding: 5px;
  }
  .alternate &{
    h4{
      a{
        background-color: $primary-color;
        color: $white-color;
        &:hover{
          background-color: $secondary-color;
          color: $dark-color;
        }
      }
    }
  }
}
