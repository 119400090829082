.hrElement{
  border-bottom: 1px solid $tertiary-color;
  .layoutContainer &{
    margin-right: 15px;
  }
}
%flex-columns{
  .column, .columnBounds{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .columnBounds{
    flex-wrap: wrap;
    > .addPageElementTop{
      min-width: 100%;
    }
    > div{
      flex-basis: 50%;
    }
  }
  .pageElement{
    margin: 0;
  }
}
@mixin flex-columns($count){
  .user_mode & .column{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;;
    // align-items: center;
    > div{
      flex-basis: (floor(100/$count) * 1%) - 2%;
    }
  }
  .edit_mode & .columnBounds{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;;
    // flex-wrap: wrap;
    > .addPageElementTop{
      min-width: 100%;
    }
    > div{
      flex-basis: (floor(100/$count) * 1%) - 2%;
    }
  }
}




// Multi-use classes
.full-width{
  @extend %full-width;
  margin-right: 0;
  padding-left: 0;
}
.full-width-container{
  @extend %full-width;
  margin-right: 0;
  padding: 3vw;
  .fw-content{
    @extend %standard-width;
    // padding: 0 $content-container-padding;
  }
  &.oneColumn{
    .pageElement{
      margin-right: 0;
    }
  }
}
.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.layoutContainer{
  @extend %full-width-image-background;
}




#home{
  .layoutContainer .pageElement h3 span{
    font-family: $secondary-font;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    color: $primary-color;
    @include font-smooth;
    b{
      font-weight: 900;
      color: $secondary-color;
    }
  }
  .yieldPageContent{
    padding-top: 0;
  }
  #yieldContent, #panelTwo{
    background: 0 !important;
  }
  .layoutContainer:not(.full-width){
    background-color: #FFF;
    // padding: 0; // old
    margin-bottom: 35px;

    .pageElement{
      margin: 0 0 20px 0; // old
      // margin: 0 25px 20px 0;

    }

    // Bottom borders
    @at-root .user_mode#home .layoutContainer:not(.full-width){
      position: relative;
      border-bottom: 2px solid $secondary-color;
      box-shadow: 0px 0px 12px -6px rgba(0,0,0,.8);
      padding: 25px 0px 10px 25px;
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        margin: 0 auto;
        width: 100%;
        max-width: 200px;
        height: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $secondary-color;
      }
      .pageElement{
        margin: 0 25px 20px 0;

      }
    }

  }
}

// Edit Mode
// #layoutsBin .layoutContainer{
//   padding: 0 !important;
// }


// Link Callouts

.link-callout{
  text-align: center;
  .linkElement{
    background-size: cover;
    background-position: center;
  }

  &.red .linkElement{
    background-color: $secondary-color;
    background-image: linear-gradient(to top, rgba($secondary-color, 1) 0%, rgba($secondary-color, .3) 100%), url(../images/baseball.png);
  }
  &.blue .linkElement{
    background-color: $primary-color;
    background-image: linear-gradient(to top, rgba($primary-color, 1) 0%, rgba($primary-color, .3) 100%), url(../images/baseball.png);
  }
  &.left .icon{
    text-align: left;
    justify-content: flex-start;
    @include respond-to(max, 650px){ justify-content: center; text-align: center;}
  }
  &.right .icon{
    text-align: right;
    justify-content: flex-end;
    @include respond-to(max, 650px){ justify-content: center; text-align: center;}
  }
  h4{
    // display: flex;
    // flex-flow: column;
    // align-items: stretch;
    // justify-content: center;
    position: relative;
    &:after{
      content:"";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $tertiary-color;
      position: absolute;
      bottom: 10px;
      left: 0px;
    }
    min-height: 106px;
    margin: 0;
  }
  .icon{
    font-family: $secondary-font;
    font-size: 28px;
    line-height: 1.2;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFF;
    background: 0;
    padding: 20px;
    width: 100%;
    min-height: 106px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
}




.flex-full, .flex-full-edit {
  min-width: 100%;
  flex-basis: 100% !important;
}
.stay-connected{

  @include flex-columns(3);
  .drag:first-of-type{
    min-width: 100%;
  }
  .pageElementAdmin .codeElement pre{
    display: none;
  }
  @include respond-to(max, 750px){
    .column, .columnBounds{
      display: block;
      > div {
        min-width: 100%;
        margin: 0;
      }
    }
  }
  .home-facebook{
    margin: 0 25px;
    > div > div,
    > div > div > span,
    > div > div > span > iframe{
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
.home{
  &-tweets,
  &-facebook,
  &-instagram{
    .pageElement{
      &:before{
        font-family: FontAwesome;
        font-size: 28px;
        text-rendering: auto;
        text-align: center;
        text-decoration: none;
        display: block;
        @include font-smooth;
        color: $secondary-color;
        margin: 0 auto 10px auto;
        line-height: 1;
      }
    }
  }
}

.home-tweets .pageElement:before{
  content: "\f099";
}
.home-facebook .pageElement:before{
  content: "\f230";
}
.home-instagram .pageElement:before{
  content: "\f16d";
}


.centered{
  img{
    margin: 0 auto;
  }
}
.contain{
  img{
    width: auto;
    max-width: 100%;
  }
}
.merge-top{
  .user_mode#home &{
    margin-top: -42px;
    box-shadow: 0px 10px 10px -12px rgba(0, 0, 0, 0.8) !important;
  }
}

.noUserBar{
  #siteHeader{
    @include respond-to(min, 769px){
      background: #11104a;
      border-bottom: 1px solid #cfb051;
      height: 30px;
    }
  }
}
