@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}


$primary-color: #11104a;
$primary-color-dk: #233e94;
$primary-color-md: #0086cb;
$primary-color-lt: #60cff6; // #0f9fc4;
$secondary-color: #c11e2d;
$tertiary-color: #cfb051;
$dark-color: #444;
$light-color: #999;
$light-color-lt: #EEE;
$white-color: #FFF;

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Montserrat', sans-serif;
$tertiary-font: 'Oswald', serif;
$quaternary-font: 'Hind Vadodara', sans-serif;


$base-padding: 15px;
$base-font-size: 16px;
$base-line-height: 1.5;
$base-font-weight: 400;

$max-content-width: 1010px;
$content-container-padding: 25px;

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%standard-width{
  max-width: $max-content-width;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  // transform: translateX(-50%); // Better solution but caused edit-mode - drag/drop to not work
  margin-left: -50vw;
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin multiply-background($color){
  background-color: $color;
  background-blend-mode: multiply;
}

$breakpoints: (
  'xs': 360px,
  'sm': 650px,
  'md': 768px,
  'lg': 1000px,
  'xl': 1200px,
);


@function em($target, $context: $base-font-size) {
  @return ($target / $context) * 1em;
}

@function rem($target) {
  @return ($target / $base-font-size) * 1rem;
}

@mixin respond-to($range, $breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (#{$range}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @media screen and (#{$range}-width: $breakpoint) {
      @content;
    }
  }
}




@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { content:''; float: left; padding-bottom: $padding;  }
  &:after  { content:''; display: table; clear: both;  }
}

@mixin update-aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { padding-bottom: $padding; }
}

@mixin font-smooth{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




// Shared Styles

%agg-headers {
  color: $primary-color;
  font-family: $secondary-font;
  font-size: rem(32px);
  font-weight: 500;
  @include respond-to(max, 400px){ font-size: rem(26px); }
  line-height: 1.2;
  margin-bottom: 25px;
}



@mixin page-element-break($break){
  // Map defining column sizes within the "md" bootstrap range
  $columns: (
    75: (($break/.75)),
    66: (($break/.66666666666)),
    50: (($break/.50)),
    33: (($break/.3333333333)),
    25: (($break/.25))
  );
  // Initial breakpoint (typically within the "sm" bootstrap range)
  // Note: Inital breakpoint also includes the panel padding, so any value you pass into $break will have the panel padding added to it.
  @media only screen and (max-width: $break){
    @content;
  }
  // Cycle through columns map checking if values are within the "md" range and exporting media queries for those that match
  @each $column, $value in $columns {
    @if $value > 650 and $value < 1000 {
      @media only screen and (min-width: 650px) and (max-width: ($value * 1px)){
        .pe#{$column}{
          @content;
        }
      }
    }
    @if $value > 1000 { // Need to find a way to lopp these into one chained selector, not multiple media queries.
      @media only screen and (min-width: 650px){
        .pe#{$column}{
          @content;
        }
      }
    }
  }
}
