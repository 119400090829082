// 1. Base Styles
* { box-sizing: border-box; }

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}
#pageContentContainer{
  $gutters: 15px;
  body:not(#home) &{
    background: #FFF;
  }
  body:not(#home):not(.edit_mode) &{
    .layoutContainer{
      padding-left: $gutters;
    }
    .pageElement{
      margin-right: $gutters;
    }
  }
}

// Rows - Content Area
#pageContentContainer{
  max-width: $max-content-width;
  width: 100%;
  margin: 0 auto;
  min-height: 80vh;
}
#siteFooter{ width:100%; }

// Columns - Panels
#panelOne{
  .user_mode &{
    // display: none;
  }
}
#panelTwo{

}
#panelOne, #panelTwo{
  box-sizing: border-box;
  width: 100%;
  margin:0;
}
@include respond-to(max, md){
  #panelOne, #panelTwo{
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #pageContentContainer{
    display: flex;
    flex-direction: column;
  }
}


// Columns - Page Elements
@include respond-to(max, sm){
  .layoutContainer{
    .pe25, .pe33, .pe50, .pe66{
      width:100%;
    }
  }
}


@media screen and (min-width: 450px) and (max-width: 768px){
  .threeColumn502525, .threeColumn252550{
    .pe25{
      width:50%;
    }
    .pe50{
      width: 100%;
    }
  }
}


// Page Elements
.layoutContainer{

}
.pageEl{

}
#displayBodyHeader{
  padding: 0 15px;
  font-family: $secondary-font;
  color: $primary-color;
  // h2& span{
  //   // font-weight:
  // }
}

// Current section Edit Mode
.subNav .drag, .subNav .lock, .cmsNavButton a{
  padding: 6px 20px 6px 8px;
  margin: 1px 0;
}
.relatedSections, #leftHandGraphics, #panelOne .cmsButton{
  display: none;
}



// Styled Elements

$background-fade: #FAFAFA;
body{
  background: url(../images/background-1.jpg), url(../images/background-0.jpg);
  background-position: center 200px, center center;
  background-repeat: repeat-x, repeat;

}
.siteContainer{
  background: transparent;
  background-image: linear-gradient(to top, rgba(#fafafa, 1) 0px, rgba(#fafafa, 0) 400px);
}
