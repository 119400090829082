@charset "UTF-8";
.navButton {
  *zoom: 1;
}

.navButton:before, .navButton:after {
  content: " ";
  display: table;
}

.navButton:after {
  clear: both;
}

#siteFooter ul:last-of-type, .main-footer .footer, #topNav .nav, .full-width-container .fw-content {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
}

.full-width, .full-width-container {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

.layoutContainer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsItemColumn .newsItemElement h2, .newsAggregatorElement .aggHeader, .eventAggregatorElement .aggHeader {
  color: #11104a;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media screen and (max-width: 400px) {
  .newsItemColumn .newsItemElement h2, .newsAggregatorElement .aggHeader, .eventAggregatorElement .aggHeader {
    font-size: 1.625rem;
  }
}

.pageElement h3,
.textBlockElement h3,
.rosterElement h3,
.aggregatorElement h3,
.tableElement h3 {
  padding: 0;
}

h3 span {
  font-size: inherit;
  line-height: 1.2;
  font-weight: inherit;
}

.twoColumn6633 .column2 .expandedView, .twoColumn3366 .column1 .expandedView, .twoColumn5050 .expandedView, .threeColumn333333 .expandedView, .threeColumn502525 .expandedView, .threeColumn255025 .expandedView, .threeColumn252550 .expandedView {
  display: table-cell;
}

.bannerGraphicTextBanner {
  display: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

body:not(#home) #pageContentContainer {
  background: #FFF;
}

body:not(#home):not(.edit_mode) #pageContentContainer .layoutContainer {
  padding-left: 15px;
}

body:not(#home):not(.edit_mode) #pageContentContainer .pageElement {
  margin-right: 15px;
}

#pageContentContainer {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
  min-height: 80vh;
}

#siteFooter {
  width: 100%;
}

#panelOne, #panelTwo {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  #panelOne, #panelTwo {
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #pageContentContainer {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .layoutContainer .pe25, .layoutContainer .pe33, .layoutContainer .pe50, .layoutContainer .pe66 {
    width: 100%;
  }
}

@media screen and (min-width: 450px) and (max-width: 768px) {
  .threeColumn502525 .pe25, .threeColumn252550 .pe25 {
    width: 50%;
  }
  .threeColumn502525 .pe50, .threeColumn252550 .pe50 {
    width: 100%;
  }
}

#displayBodyHeader {
  padding: 0 15px;
  font-family: "Montserrat", sans-serif;
  color: #11104a;
}

.subNav .drag, .subNav .lock, .cmsNavButton a {
  padding: 6px 20px 6px 8px;
  margin: 1px 0;
}

.relatedSections, #leftHandGraphics, #panelOne .cmsButton {
  display: none;
}

body {
  background: url(../images/background-1.jpg), url(../images/background-0.jpg);
  background-position: center 200px, center center;
  background-repeat: repeat-x, repeat;
}

.siteContainer {
  background: transparent;
  background-image: linear-gradient(to top, #fafafa 0px, rgba(250, 250, 250, 0) 400px);
}

html,
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #444;
}

body {
  font-size: 100%;
}

.newsTags a {
  color: #11104a;
  text-decoration: none;
  transition: color .2s linear;
}

.newsTags a:hover {
  color: black;
}

.pageElement .text p,
.pageElement .previewText p,
.pageElement .newsText p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.pageElement .text h1, .pageElement .text h2, .pageElement .text h3, .pageElement .text h4, .pageElement .text h5, .pageElement .text h6,
.pageElement .previewText h1,
.pageElement .previewText h2,
.pageElement .previewText h3,
.pageElement .previewText h4,
.pageElement .previewText h5,
.pageElement .previewText h6,
.pageElement .newsText h1,
.pageElement .newsText h2,
.pageElement .newsText h3,
.pageElement .newsText h4,
.pageElement .newsText h5,
.pageElement .newsText h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.125;
  color: #444;
}

.pageElement .text h1,
.pageElement .previewText h1,
.pageElement .newsText h1 {
  font-size: 2.375rem;
}

.pageElement .text h2,
.pageElement .previewText h2,
.pageElement .newsText h2 {
  font-size: 2rem;
}

.pageElement .text h3,
.pageElement .previewText h3,
.pageElement .newsText h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.pageElement .text h4,
.pageElement .previewText h4,
.pageElement .newsText h4 {
  font-size: 1.125rem;
  text-transform: uppercase;
}

.pageElement .text h5,
.pageElement .previewText h5,
.pageElement .newsText h5 {
  font-size: 1rem;
  text-transform: uppercase;
}

.pageElement .text h6,
.pageElement .previewText h6,
.pageElement .newsText h6 {
  font-size: 1rem;
}

.pageElement .text ul, .pageElement .text ol,
.pageElement .previewText ul,
.pageElement .previewText ol,
.pageElement .newsText ul,
.pageElement .newsText ol {
  margin-left: 10px;
}

.pageElement .text ul,
.pageElement .previewText ul,
.pageElement .newsText ul {
  list-style-type: disc;
}

.pageElement .text li,
.pageElement .previewText li,
.pageElement .newsText li {
  margin: 0;
  line-height: 1.5;
}

.pageElement .text a,
.pageElement .previewText a,
.pageElement .newsText a {
  color: #c11e2d;
  text-decoration: none;
  transition: color .2s linear;
  position: relative;
  display: inline-block;
}

.pageElement .text a:after,
.pageElement .previewText a:after,
.pageElement .newsText a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #11104a;
  opacity: 0;
  transform: translateY(6px);
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.pageElement .text a:hover, .pageElement .text a:focus,
.pageElement .previewText a:hover,
.pageElement .previewText a:focus,
.pageElement .newsText a:hover,
.pageElement .newsText a:focus {
  color: #c11e2d;
}

.pageElement .text a:hover:after, .pageElement .text a:focus:after,
.pageElement .previewText a:hover:after,
.pageElement .previewText a:focus:after,
.pageElement .newsText a:hover:after,
.pageElement .newsText a:focus:after {
  opacity: 1;
  transform: translateY(1px);
}

.pageElement .text b, .pageElement .text strong,
.pageElement .previewText b,
.pageElement .previewText strong,
.pageElement .newsText b,
.pageElement .newsText strong {
  font-weight: bold;
}

.pageElement .text i, .pageElement .text em,
.pageElement .previewText i,
.pageElement .previewText em,
.pageElement .newsText i,
.pageElement .newsText em {
  font-style: italic;
}

.pageElement .text small,
.pageElement .previewText small,
.pageElement .newsText small {
  font-size: .75em;
}

.pageElement .text mark,
.pageElement .previewText mark,
.pageElement .newsText mark {
  background-color: yellow;
}

.pageElement .text s, .pageElement .text strike, .pageElement .text del,
.pageElement .previewText s,
.pageElement .previewText strike,
.pageElement .previewText del,
.pageElement .newsText s,
.pageElement .newsText strike,
.pageElement .newsText del {
  text-decoration: line-through;
}

.pageElement .text u,
.pageElement .previewText u,
.pageElement .newsText u {
  text-decoration: underline;
}

.pageElement .text code,
.pageElement .previewText code,
.pageElement .newsText code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.pageElement .text code, .pageElement .text kbd, .pageElement .text pre, .pageElement .text samp,
.pageElement .previewText code,
.pageElement .previewText kbd,
.pageElement .previewText pre,
.pageElement .previewText samp,
.pageElement .newsText code,
.pageElement .newsText kbd,
.pageElement .newsText pre,
.pageElement .newsText samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.pageElement .text pre,
.pageElement .previewText pre,
.pageElement .newsText pre {
  display: block;
  padding: 15px;
  margin: 0 0 1rem 0;
  font-size: 90%;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pageElement .text blockquote,
.pageElement .previewText blockquote,
.pageElement .newsText blockquote {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.35;
  color: #11104a;
  padding: 0 0 0 10px;
  margin: 10px;
}

.pageElement .text blockquote:before,
.pageElement .previewText blockquote:before,
.pageElement .newsText blockquote:before {
  content: "“";
  display: inline-block;
  font-weight: 900;
  color: #CCC;
}

.pageElement .text blockquote:after,
.pageElement .previewText blockquote:after,
.pageElement .newsText blockquote:after {
  content: "”";
  display: inline-block;
  font-weight: 900;
  color: #CCC;
}

.pageElement .text blockquote p,
.pageElement .previewText blockquote p,
.pageElement .newsText blockquote p {
  display: inline;
  line-height: 1.35;
  font-size: 18px;
}

.pageElement .text > p:last-child, .pageElement .text > h1:last-child, .pageElement .text > h2:last-child, .pageElement .text > h3:last-child, .pageElement .text > h4:last-child, .pageElement .text > h5:last-child, .pageElement .text > h6:last-child, .pageElement .text > ol:last-child, .pageElement .text > ul:last-child, .pageElement .text > blockquote:last-child, .pageElement .text > pre:last-child,
.pageElement .previewText > p:last-child,
.pageElement .previewText > h1:last-child,
.pageElement .previewText > h2:last-child,
.pageElement .previewText > h3:last-child,
.pageElement .previewText > h4:last-child,
.pageElement .previewText > h5:last-child,
.pageElement .previewText > h6:last-child,
.pageElement .previewText > ol:last-child,
.pageElement .previewText > ul:last-child,
.pageElement .previewText > blockquote:last-child,
.pageElement .previewText > pre:last-child,
.pageElement .newsText > p:last-child,
.pageElement .newsText > h1:last-child,
.pageElement .newsText > h2:last-child,
.pageElement .newsText > h3:last-child,
.pageElement .newsText > h4:last-child,
.pageElement .newsText > h5:last-child,
.pageElement .newsText > h6:last-child,
.pageElement .newsText > ol:last-child,
.pageElement .newsText > ul:last-child,
.pageElement .newsText > blockquote:last-child,
.pageElement .newsText > pre:last-child {
  margin-bottom: 0;
}

.pageElement .text ul, .pageElement .text ol,
.pageElement .previewText ul,
.pageElement .previewText ol,
.pageElement .newsText ul,
.pageElement .newsText ol {
  margin-left: 10px;
  margin-bottom: 1em;
}

.pageElement .text ul li, .pageElement .text ol li,
.pageElement .previewText ul li,
.pageElement .previewText ol li,
.pageElement .newsText ul li,
.pageElement .newsText ol li {
  margin-left: 10px;
}

.pageElement .text ul li > ul, .pageElement .text ul li > ol, .pageElement .text ol li > ul, .pageElement .text ol li > ol,
.pageElement .previewText ul li > ul,
.pageElement .previewText ul li > ol,
.pageElement .previewText ol li > ul,
.pageElement .previewText ol li > ol,
.pageElement .newsText ul li > ul,
.pageElement .newsText ul li > ol,
.pageElement .newsText ol li > ul,
.pageElement .newsText ol li > ol {
  margin-bottom: 0;
}

.pageElement .text table,
.pageElement .previewText table,
.pageElement .newsText table {
  border-color: #999;
  margin-bottom: 1rem;
}

.pageElement .text table th, .pageElement .text table td,
.pageElement .previewText table th,
.pageElement .previewText table td,
.pageElement .newsText table th,
.pageElement .newsText table td {
  padding: 2px 12px;
}

.pageElement .text table:not([border]),
.pageElement .previewText table:not([border]),
.pageElement .newsText table:not([border]) {
  border-style: none;
  border-spacing: 2px;
  border-collapse: collapse;
}

.pageElement .text table:not([border]) td,
.pageElement .previewText table:not([border]) td,
.pageElement .newsText table:not([border]) td {
  font-size: 12px;
  padding: 2px 12px;
  height: 30px;
}

.pageElement .text table:not([border]) th,
.pageElement .previewText table:not([border]) th,
.pageElement .newsText table:not([border]) th {
  height: 30px;
  padding: 3px 12px;
  background: #11104a;
  white-space: nowrap;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  font: 600 12px/1.2 "Montserrat", sans-serif;
}

.pageElement .text table:not([border]) td, .pageElement .text table:not([border]) th,
.pageElement .previewText table:not([border]) td,
.pageElement .previewText table:not([border]) th,
.pageElement .newsText table:not([border]) td,
.pageElement .newsText table:not([border]) th {
  border: 1px solid #999;
}

.pageElement .text img,
.pageElement .previewText img,
.pageElement .newsText img {
  max-width: 100%;
}

.tableWrapper table {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.tableElement .odd {
  background-color: transparent;
}

.tableElement tbody tr {
  border-bottom: 2px solid #444;
}

.tableElement table tr th {
  background: #333;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tableElement .ajax_sort:hover {
  color: #11104a;
}

.dataTable td, .dataTable th {
  padding: 10px;
}

.table-wrap {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
}

#siteFooter {
  background: linear-gradient(#11104a, #11104a), url(../images/field.jpg);
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 2px solid #c11e2d;
  padding: 15px;
}

.logged_in #siteFooter {
  padding-bottom: 45px;
}

#siteFooter ul:last-of-type {
  font-size: 12px;
  color: #FFF;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 15px;
  border-top: 1px solid #888;
}

#siteFooter ul:last-of-type li {
  margin-right: 10px;
}

#siteFooter ul:last-of-type li#poweredByNGIN {
  margin-right: 0;
  margin-left: auto;
}

#siteFooter ul:last-of-type li a {
  color: #FFF;
}

#siteFooter ul:last-of-type li a:hover {
  color: #c11e2d;
}

.main-footer {
  margin-bottom: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
}

.main-footer .footer {
  padding: 0;
}

.main-footer .column {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

@media screen and (max-width: 820px) {
  .main-footer .column {
    flex-wrap: wrap;
  }
}

.main-footer .column:before {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  max-width: 150px;
  height: 105px;
  background: url(../images/site-logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (max-width: 820px) {
  .main-footer .column:before {
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.main-footer .text p {
  font-size: 14px;
}

.main-footer .pageElement h3 span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid #cfb051;
  padding-bottom: 3px;
}

.main-footer .contact-us, .main-footer .stay-connected, .main-footer .quick-links {
  width: 30%;
}

@media screen and (max-width: 540px) {
  .main-footer .contact-us, .main-footer .stay-connected, .main-footer .quick-links {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .main-footer .contact-us .textBlockElement h3, .main-footer .stay-connected .textBlockElement h3, .main-footer .quick-links .textBlockElement h3 {
    text-align: center !important;
  }
}

.main-footer .contact-us .pageElement, .main-footer .stay-connected .pageElement, .main-footer .quick-links .pageElement {
  margin: 0;
}

.main-footer .contact-us {
  text-align: left;
}

.main-footer .stay-connected {
  text-align: center;
}

.main-footer .stay-connected:before {
  content: "Stay Connected";
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid #cfb051;
  padding-bottom: 3px;
  margin-bottom: 9px;
}

.main-footer .stay-connected .sn-social-media-list .sn-social-media-icon {
  border-radius: 0;
  background-color: #c11e2d;
  transition: background-color .2s linear;
}

.main-footer .stay-connected .sn-social-media-list .sn-social-media-icon:hover {
  opacity: 1;
  background-color: #951723;
}

.main-footer .quick-links {
  text-align: right;
}

.main-footer .quick-links .textBlockElement h3 {
  text-align: right !important;
}

.main-footer .link-list a {
  text-decoration: none;
  color: #FFF;
  margin-left: 10px;
}

.main-footer .link-list a:hover {
  color: #cfb051;
}

@media screen and (max-width: 540px) {
  .main-footer .contact-us, .main-footer .stay-connected, .main-footer .quick-links {
    width: 100%;
    margin: 15px 0;
    text-align: center;
  }
  .main-footer .contact-us .textBlockElement h3, .main-footer .stay-connected .textBlockElement h3, .main-footer .quick-links .textBlockElement h3 {
    text-align: center !important;
  }
}

#siteHeader h1:empty,
#siteMiniHeader h1:empty {
  display: none;
}

#accountNav a {
  box-sizing: content-box;
}

#siteHeader .adminToggle {
  position: fixed;
  top: auto;
  bottom: 0;
  right: 0;
  z-index: 10000;
}

#siteHeader #adminNav {
  position: fixed;
  top: auto;
  bottom: 0;
  right: 80px;
  z-index: 10000;
}

#siteHeader #adminNav a {
  margin: 0;
  line-height: 1;
}

#siteHeader .adminToggle, #siteHeader #adminNav {
  top: initial;
  left: initial;
  position: fixed;
  z-index: 9998;
}

#siteHeader #adminNav {
  right: 3px;
  bottom: 5px;
}

#siteHeader #adminNav a {
  margin: 0;
}

#siteHeader .adminToggle {
  right: 145px;
  bottom: 6px;
}

#siteHeader #adminNav {
  height: 40px;
  right: 0;
  bottom: 0;
}

#siteHeader #adminNav a {
  text-decoration: none;
  display: block;
  overflow: hidden;
  height: 40px;
  color: #808F98;
  transition: .2s ease-out;
}

#siteHeader #adminNav a:hover {
  color: #2598C6;
}

#siteHeader #adminNav > a:before {
  text-align: left;
  width: 100%;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  display: block;
  position: relative;
  padding: 0px 0px 0px 60px;
  top: 50%;
  transform: translateY(-50%);
}

#siteHeader #adminNav img {
  display: none;
}

.user_mode #adminNav {
  background: url("../images/switch_off.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
}

.user_mode #adminNav > a:last-of-type:before {
  content: 'Edit Mode';
}

.edit_mode #adminNav {
  background: url("../images/switch_on.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
}

.edit_mode #adminNav > a:before {
  content: 'User Mode';
}

#siteHeader .adminToggle {
  background: url("../images/admin_gear.png") 50% 50% no-repeat;
  height: 40px;
  width: 40px;
  right: 138px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
}

#siteHeader #adminNav .admin-trash {
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 178px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
}

#siteHeader #adminNav .admin-trash:before {
  display: none !important;
}

#siteHeader #adminNav .admin-site-guide {
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 232px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
}

#siteHeader #adminNav .admin-site-guide:before {
  display: none !important;
}

.row-1 {
  padding: 0;
  border-bottom: 5px solid #11104a;
}

.row-1 .pageElement {
  margin: 0;
}

@media screen and (max-width: 768px) {
  #accountNav, #adminNav, .adminToggle {
    display: none;
  }
}

#topNav {
  border-bottom: 3px solid #c11e2d;
}

#siteContainer2.one_panel #topNav, #topNav {
  background-color: #11104a;
}

#topNav .nav {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  justify-content: space-between;
  list-style: none;
  min-height: 80px;
}

#topNav .nav-left,
#topNav .nav-right {
  width: 50%;
}

#topNav .nav-mid {
  width: 150px;
  min-width: 150px;
  position: relative;
}

#topNav .nav-mid a {
  background: url("../images/site-logo-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 150px;
  height: 138px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: 15;
}

#topNav .split-nav {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  justify-content: space-between;
}

#topNav .split-nav li,
#topNav .split-nav ul {
  list-style: none;
}

#topNav .split-nav > li {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  justify-content: center;
  position: relative;
  z-index: 99;
}

#topNav .split-nav > li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 5px 3px;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 840px) {
  #topNav .split-nav > li a {
    font-size: 18px;
  }
}

#topNav .split-nav > li > a:after, #topNav .split-nav > li > a:before {
  content: "";
  position: absolute;
  left: 0;
  transition: height .2s linear;
  padding: 0;
  height: 0;
  background-color: #c11e2d;
  width: 100%;
  z-index: 5;
}

#topNav .split-nav > li > a:before {
  top: 100%;
  transform: translateY(0px);
}

#topNav .split-nav > li > a:after {
  bottom: -3px;
  transform: translateY(0px);
}

#topNav .split-nav > li.hover > a, #topNav .split-nav > li.selected > a, #topNav .split-nav > li:hover > a {
  position: relative;
}

#topNav .split-nav > li.hover > a:after, #topNav .split-nav > li.hover > a:before, #topNav .split-nav > li.selected > a:after, #topNav .split-nav > li.selected > a:before, #topNav .split-nav > li:hover > a:after, #topNav .split-nav > li:hover > a:before {
  height: 7px;
}

#topNav .split-nav > li:hover .dropdown {
  display: block;
}

#topNav .split-nav > li:hover .dropdown li:hover ul {
  display: block;
}

#topNav .split-nav > li ul {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
}

#topNav .split-nav > li ul ul {
  transform: translateX(5px);
  min-width: 150px;
  background: rgba(34, 34, 34, 0.9);
  top: 0;
  left: 100%;
}

#topNav .split-nav > li ul ul.right {
  right: 100%;
  left: auto;
  transform: translateX(-5px);
}

#topNav .split-nav > li ul.dropdown {
  transform: translateY(3px) translateX(-50%);
  left: 50%;
  min-width: 180px;
  background: rgba(34, 34, 34, 0.9);
  z-index: -1;
}

#topNav .split-nav > li ul.dropdown li {
  position: relative;
}

#topNav .split-nav > li ul.dropdown a {
  font-size: 14px;
  text-align: left;
}

#topNav .split-nav > li ul.dropdown.right {
  right: 0;
  left: auto;
  transform: translateY(3px);
}

#topNav .split-nav > li ul.dropdown.right .dropdown-disclosure {
  left: 0;
  transform: rotate(180deg);
}

#topNav .split-nav > li ul li {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

#topNav .split-nav > li ul li + li {
  border-top: 1px solid #666;
}

#topNav .split-nav > li ul li a {
  padding: 10px;
  color: #FFF;
  transition: padding .2s linear;
}

#topNav .split-nav > li ul li.hover > a,
#topNav .split-nav > li ul li:hover > a {
  padding: 10px 4px 10px 16px;
}

#topNav .split-nav > li ul li a.disabled {
  background: rgba(255, 255, 255, 0.2);
  color: #AAA;
}

#topNav .dropdown,
#topNav .dropdown ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

#topNav a {
  text-decoration: none;
}

body.user_mode #nav_id-page_node_2249523 {
  display: none;
}

.mobileNav {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileNav {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  #topNav {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .site {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #FFF;
    height: 60px;
    background-color: #11104a;
  }
  .site a {
    display: inline-block;
    height: 50px;
    width: 60px;
    background-image: url(../images/site-logo-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
  }
}

.navButton {
  display: none;
  position: relative;
  overflow: hidden;
  margin: 0 0 0 10px;
  padding: 10px;
  width: 50px;
  height: 40px;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
}

@media screen and (max-width: 768px) {
  .navButton {
    display: block;
  }
}

.navButton:focus {
  outline: none;
}

.navButton span {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  right: 10px;
  height: 3px;
  background: #FFF;
}

.navButton span::before,
.navButton span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #FFF;
  content: "";
}

.navButton span::before {
  top: -9px;
}

.navButton span::after {
  bottom: -9px;
}

.navButton {
  background-color: #11104a;
}

.navButton span {
  transition: background 0s 0.1s;
}

.navButton span::before,
.navButton span::after {
  transition-duration: 0.1s, 0.1s;
  transition-delay: 0.1s, 0s;
}

.navButton span::before {
  transition-property: top, transform;
}

.navButton span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.navButton.expanded span {
  background: none;
}

.navButton.expanded span::before {
  top: 0;
  transform: rotate(45deg);
}

.navButton.expanded span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.navButton.expanded span::before,
.navButton.expanded span::after {
  transition-delay: 0s, 0.1s;
}

/* Styling */
.mobileNav {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileNav.expanded {
    display: block;
  }
}

.mobileNav li {
  text-align: left;
  background-color: #11104a;
  border-bottom: 1px solid #233e94;
  transition: color .2s linear, background-color .2s linear;
}

.mobileNav li a {
  color: #FFF;
  transition: color .2s linear, background-color .2s linear;
}

.mobileNav .dropdown.expanded {
  border-top: 1px solid #0086cb;
}

.mobileNav li.expanded, .mobileNav li.expanded li.expanded {
  border: none;
}

.mobileNav .lastNavElement .dropdown > li.expanded:last-of-type {
  padding: 0;
}

.mobileNav .dropdown li {
  background-color: #222;
  border-bottom: none;
}

.mobileNav .dropdown li:last-of-type {
  border-bottom: 1px solid #0086cb;
}

.mobileNav .dropdown li a {
  color: #FFF;
}

.mobileNav .dropdown li li {
  background-color: #FFF;
  border-bottom: none;
}

.mobileNav .dropdown li li a {
  color: #11104a;
}

.mobileNav .dropdown li ul {
  border-top: 1px solid #444;
}

.mobileNav li:hover, .mobileNav li.selected {
  background-color: #233e94;
}

.mobileNav li li:hover, .mobileNav li li.selected {
  background-color: #233e94;
}

.mobileNav li li li:hover, .mobileNav li li li.selected {
  background-color: #FFF;
}

.mobileNav li a:hover, .mobileNav li a.selected {
  color: #FFF;
}

.mobileNav li li a:hover, .mobileNav li li a.selected {
  color: #c11e2d;
}

.mobileNav a, .mobileNav .nodeToggle {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  padding: 10px;
  text-decoration: none;
}

.mobileNav .dropdown a {
  display: inline-block;
  transition: color .2s linear;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
  letter-spacing: 0;
  padding: 10px 0 10px 20px;
}

.mobileNav .dropdown ul a {
  margin-left: 20px;
}

.mobileNav .sn-tag-logo, .mobileNav .site-logo {
  display: none;
}

.nodeToggle {
  cursor: pointer;
  display: inline-block;
  padding-left: 0;
}

.nodeToggle:before {
  border-color: #FFF;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  left: 0;
  position: relative;
  top: 4px;
  transform: rotate(45deg);
  vertical-align: top;
  width: 8px;
  transition: transform .1s linear;
}

li li .nodeToggle:before {
  border-color: #FFF;
}

.nodeToggle.expanded:before {
  transform: rotate(135deg);
}

.nodeToggle + ul {
  display: none;
}

@media screen and (max-width: 768px) {
  .site-header {
    margin: 0;
    padding: 10px 10px 20px 10px;
    max-width: 100%;
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .siteContainer:before {
    height: 150px;
  }
}

#accountNav {
  background: #11104a;
  border-bottom: 1px solid #cfb051;
}

#accountNav a {
  border: none;
}

#accountNav .nginLogo a {
  background: url(../images/sportngin-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#accountNav .currentSite a#edit_membership {
  border: none;
}

#panelOne {
  padding: 30px 15px 0 15px;
}

#panelOne .subNav {
  font-size: 14px;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid #cfb051;
}

#panelOne .subNav h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1em;
  display: inline-block;
  display: none;
  padding-top: 1px;
}

#panelOne .subNav h4:after {
  content: "\0399";
  display: inline-block;
  margin: 0 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
}

#panelOne .subNav .parents {
  display: flex;
  flex-flow: row wrap;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1em;
}

.user_mode #panelOne .subNav .parents li + li {
  margin-left: 10px;
}

.user_mode #panelOne .subNav .parents li + li:before {
  content: "\203A";
  color: #999;
  display: inline-block;
  margin-right: 10px;
  transform: translateY(-1px);
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
}

.user_mode #panelOne .subNav .parents li + li.selected a {
  color: #c11e2d;
}

#panelOne .subNav .parents li a {
  display: inline-block;
  color: #999;
  transition: color .2s linear;
  padding: 5px 0;
  font-size: inherit;
}

#panelOne .subNav .parents li a:hover {
  color: #11104a;
}

#panelOne .subNav .parents .cmsNavButton {
  margin-left: 10px;
}

#panelOne .subNav .children {
  margin-top: 1em;
}

.user_mode #panelOne .subNav .children {
  display: none;
}

.home-slider .newsSlideShow {
  margin: 0;
}

.newsSlideShow-article {
  display: flex;
  align-items: flex-end;
  padding: 0;
  height: calc(100% - 30px);
}

.newsSlideShow-article .newsSlideShow-headline {
  background: rgba(17, 16, 74, 0.9);
  position: relative !important;
  z-index: 1;
  bottom: auto;
  color: inherit;
  padding: 20px;
  width: 100%;
  border-bottom: 2px solid #c11e2d;
}

.newsSlideShow-article .newsSlideShow-headline h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2;
  display: block;
  padding: 0;
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 300px) {
  .newsSlideShow-article .newsSlideShow-headline h1 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}

.newsSlideShow-article .newsSlideShow-headline h1 a {
  height: auto;
}

.newsSlideShow-article .newsSlideShow-headline p {
  display: none;
}

.newsSlideShow-article img {
  top: 50% !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-50%);
}

.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 {
  height: initial !important;
}

.aspect_ratio_original:before {
  content: '';
  float: left;
  padding-bottom: 56.25%;
}

.aspect_ratio_original:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_1_1:before {
  content: '';
  float: left;
  padding-bottom: 100%;
}

.aspect_ratio_1_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_4_3:before {
  content: '';
  float: left;
  padding-bottom: 75%;
}

.aspect_ratio_4_3:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_16_9:before {
  content: '';
  float: left;
  padding-bottom: 56.25%;
}

.aspect_ratio_16_9:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_2_1:before {
  content: '';
  float: left;
  padding-bottom: 50%;
}

.aspect_ratio_2_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_3_1:before {
  content: '';
  float: left;
  padding-bottom: 33.33333%;
}

.aspect_ratio_3_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_4_1:before {
  content: '';
  float: left;
  padding-bottom: 25%;
}

.aspect_ratio_4_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_8_5:before {
  content: '';
  float: left;
  padding-bottom: 62.5%;
}

.aspect_ratio_8_5:after {
  content: '';
  display: table;
  clear: both;
}

.newsSlideShow-container {
  background: none;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 30px;
}

.newsSlideShow-container .newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  padding: 0;
  left: 50%;
  bottom: 1px;
  width: 100%;
  height: 30px;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.newsSlideShow-container .newsSlideShow-thumbnails li {
  display: inline-block;
  width: 100px;
  margin: 0 5px;
  cursor: pointer;
}

.newsSlideShow-container .newsSlideShow-thumbnails a {
  opacity: 1;
  width: 100%;
  height: 10px;
  display: block;
  font-size: 1.25em;
  border: 4px solid #c11e2d;
  background: #cfb051;
}

.newsSlideShow-container .newsSlideShow-thumbnails a:hover, .newsSlideShow-container .newsSlideShow-thumbnails a.current {
  background: #c11e2d;
  border: 4px solid #c11e2d;
}

.newsSlideShow-container .newsSlideShow-thumbnails img {
  display: none;
  width: 100%;
}

.newsSlideShow-container .newsSlideShow-controls {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.newsSlideShow-container .newsSlideShow-prev,
.newsSlideShow-container .newsSlideShow-next {
  text-shadow: none;
}

.newsSlideShow-container .newsSlideShow-prev:after,
.newsSlideShow-container .newsSlideShow-next:after {
  font-size: 45px;
}

.newsSlideShow-container .newsSlideShow-prev.pill-small-left:after {
  content: "\2039";
}

.newsSlideShow-container .newsSlideShow-next.pill-small-right:after {
  content: "\203A";
}

.newsSlideShow-container .newsSlideShow-prev.pill-small-left,
.newsSlideShow-container .newsSlideShow-next.pill-small-right {
  position: relative;
  z-index: 10;
  bottom: auto;
  overflow: hidden;
  padding: 0;
  background-color: transparent;
  box-shadow: 0;
  font-size: 0;
  text-align: center;
  color: #cfb051;
}

.newsSlideShow-container .newsSlideShow-prev.pill-small-left:after,
.newsSlideShow-container .newsSlideShow-next.pill-small-right:after {
  line-height: 1;
  text-indent: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  padding: 0;
  display: block;
  color: inherit;
  font-family: 'fontawesome';
  transition: transform .1s ease-in-out, color .1s ease-in-out;
}

.newsItemColumn {
  padding-top: 0;
}

.newsItemColumn .newsPreviewThumb {
  width: 100%;
  float: none;
  margin: 0;
  width: auto !important;
  max-width: 100%;
}

.newsItemColumn .newsItemElement {
  border-bottom: 2px solid #EEE;
  margin-bottom: 20px !important;
}

.newsItemColumn .newsItemElement h2 {
  margin: 20px 0;
}

.newsItemColumn .newsItemElement h2:first-child {
  margin-top: 0;
}

.newsItemColumn .newsItemElement h4 {
  margin: 0 0 20px 0;
}

.newsItemColumn .newsDate, .newsItemColumn .newsAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
}

.newsItemColumn .newsAuthor {
  margin: 10px 0 20px 0;
}

.newsTags {
  font-size: 16px;
  line-height: 1.5;
  color: #444;
}

.siteMapElement {
  background: none;
  border: none;
  margin: 0 0 10px 0 !important;
  padding: 0;
}

.siteMapElement a {
  text-decoration: none;
}

.siteMapElement .unlimitedHierarchy {
  background: none;
  margin: 0;
  width: auto;
}

.siteMapElement .unlimitedHierarchy ul {
  margin-left: .75em;
  border-left: 1px solid #AAA;
  padding-bottom: 1em;
}

.siteMapElement .unlimitedHierarchy li {
  display: block;
  padding: 0 0 0 5px;
  clear: both;
  list-style: none;
  background: none;
}

.siteMapElement .unlimitedHierarchy li li {
  margin-left: 1.5em;
  padding: 0;
}

.siteMapElement .unlimitedHierarchy li div {
  padding: 5px;
  background: none;
}

.siteMapElement .unlimitedHierarchy .lastLi {
  background: none;
}

.siteMapElement .unlimitedHierarchy .lastLi > ul {
  padding-bottom: 0;
  margin-bottom: 1em;
}

.newsAggregatorElement a {
  text-decoration: none;
}

.newsAggregatorElement .item {
  line-height: 1.2;
  border: none;
  padding: 0;
}

.newsAggregatorElement .item + .item {
  padding-top: 5px;
}

.newsAggregatorElement .item.odd {
  background-color: transparent;
}

.newsAggregatorElement .item.condensed {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.newsAggregatorElement .item.condensed .expandedView {
  display: none;
}

.newsAggregatorElement .item.condensed > a {
  display: block;
  width: 95px !important;
  position: absolute;
  overflow: hidden;
}

.newsAggregatorElement .commentsAndMore {
  display: none;
}

.newsAggregatorElement .condensed h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.2;
}

.newsAggregatorElement .condensed h4 a {
  color: #444;
}

.newsAggregatorElement .condensed h4 a:hover {
  color: #11104a;
}

.newsAggregatorElement .condensed .dateAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6875rem;
  font-style: italic;
  line-height: 1;
  padding-top: 5px;
}

.newsAggregatorElement .expanded .newsItemElement h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}

.newsAggregatorElement .expanded .newsItemElement h2 a {
  color: #444;
}

.newsAggregatorElement .expanded .newsItemElement h2 a:hover {
  color: #11104a;
}

.newsAggregatorElement .expanded .newsDate, .newsAggregatorElement .expanded .newsAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6875rem;
  font-style: italic;
  line-height: 1;
  padding-top: 5px;
}

.newsAggregatorElement .details {
  margin-left: 100px;
  padding: 10px;
  width: 100%;
  min-height: 95px;
}

.newsAggregatorElement .odd .details {
  background: #fafafa;
}

.newsAggregatorElement .even .details {
  background: #f3f3f3;
}

.newsAggregatorElement a img {
  width: 95px !important;
}

.aggregatorElement .extendedOptions {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  margin: 0;
  padding: 5px 0;
  border: none !important;
  background-color: transparent;
}

.aggregatorElement .extendedOptions a {
  text-decoration: none;
}

.aggregatorElement .extendedOptions .icon {
  padding: 0;
  background: none;
  padding: 0 2px;
  color: #999;
  transition: .2s linear;
}

.aggregatorElement .extendedOptions .icon:hover {
  color: #11104a;
}

@media screen and (max-width: 290px) {
  .column .newsAggregatorElement .item.condensed {
    flex-wrap: wrap;
    padding: 10px 5px;
  }
  .column .newsAggregatorElement .item.condensed + .item {
    margin-top: 5px;
  }
  .column .newsAggregatorElement .item.condensed.odd {
    background: #fafafa;
  }
  .column .newsAggregatorElement .item.condensed.even {
    background: #f3f3f3;
  }
  .column .newsAggregatorElement .item.condensed > a {
    position: relative;
    width: 100% !important;
  }
  .column .newsAggregatorElement a img {
    float: none;
    width: 75px !important;
    display: block;
  }
  .column .newsAggregatorElement .condensed a img {
    margin: 0 auto;
  }
  .column .newsAggregatorElement .details {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }
}

@media screen and (min-width: 651px) and (max-width: 770px) {
  .pe33 .newsAggregatorElement .item.condensed {
    flex-wrap: wrap;
    padding: 10px 5px;
  }
  .pe33 .newsAggregatorElement .item.condensed + .item {
    margin-top: 5px;
  }
  .pe33 .newsAggregatorElement .item.condensed.odd {
    background: #fafafa;
  }
  .pe33 .newsAggregatorElement .item.condensed.even {
    background: #f3f3f3;
  }
  .pe33 .newsAggregatorElement .item.condensed > a {
    position: relative;
    width: 100% !important;
  }
  .pe33 .newsAggregatorElement a img {
    float: none;
    width: 75px !important;
    display: block;
  }
  .pe33 .newsAggregatorElement .condensed a img {
    margin: 0 auto;
  }
  .pe33 .newsAggregatorElement .details {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }
}

@media screen and (min-width: 651px) {
  .threeColumn255025 .pe25 .newsAggregatorElement .item.condensed {
    flex-wrap: wrap;
    padding: 10px 5px;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .item.condensed + .item {
    margin-top: 5px;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .item.condensed.odd {
    background: #fafafa;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .item.condensed.even {
    background: #f3f3f3;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .item.condensed > a {
    position: relative;
    width: 100% !important;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement a img {
    float: none;
    width: 75px !important;
    display: block;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .condensed a img {
    margin: 0 auto;
  }
  .threeColumn255025 .pe25 .newsAggregatorElement .details {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }
}

@media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px) {
  .threeColumn502525 .pe25 .newsAggregatorElement .item.condensed, .threeColumn252550 .pe25 .newsAggregatorElement .item.condensed {
    flex-wrap: wrap;
    padding: 10px 5px;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .item.condensed + .item, .threeColumn252550 .pe25 .newsAggregatorElement .item.condensed + .item {
    margin-top: 5px;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .item.condensed.odd, .threeColumn252550 .pe25 .newsAggregatorElement .item.condensed.odd {
    background: #fafafa;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .item.condensed.even, .threeColumn252550 .pe25 .newsAggregatorElement .item.condensed.even {
    background: #f3f3f3;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .item.condensed > a, .threeColumn252550 .pe25 .newsAggregatorElement .item.condensed > a {
    position: relative;
    width: 100% !important;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement a img, .threeColumn252550 .pe25 .newsAggregatorElement a img {
    float: none;
    width: 75px !important;
    display: block;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .condensed a img, .threeColumn252550 .pe25 .newsAggregatorElement .condensed a img {
    margin: 0 auto;
  }
  .threeColumn502525 .pe25 .newsAggregatorElement .details, .threeColumn252550 .pe25 .newsAggregatorElement .details {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }
}

.eventAggregatorElement .even, .eventAggregatorElement .odd {
  background-color: transparent;
}

.eventAggregatorElement a {
  text-decoration: none;
}

.eventAggregatorElement .vevent {
  line-height: 1.2;
  border: none;
  padding: 0 0 0 0;
  min-height: 55px;
}

.eventAggregatorElement .vevent + .vevent {
  padding-top: 5px;
  min-height: 55px;
}

.eventAggregatorElement .vevent.odd {
  background: 0;
}

.eventAggregatorElement .vevent.odd .summary, .eventAggregatorElement .vevent.odd .details, .eventAggregatorElement .vevent.odd .dateImage {
  background-color: #fafafa;
}

.eventAggregatorElement .vevent.even {
  background: 0;
}

.eventAggregatorElement .vevent.even .summary, .eventAggregatorElement .vevent.even .details, .eventAggregatorElement .vevent.even .dateImage {
  background-color: #f3f3f3;
}

.eventAggregatorElement .vevent .dateImage {
  width: 50px;
  padding: 10px 5px;
}

.eventAggregatorElement .vevent .dateImage .month {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #cfb051;
  text-transform: uppercase;
}

.eventAggregatorElement .vevent .dateImage .date {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #cfb051;
}

.eventAggregatorElement .vevent .summary, .eventAggregatorElement .vevent .details {
  margin-left: 55px;
  padding: 10px;
}

.eventAggregatorElement .vevent .summary {
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 0;
}

.eventAggregatorElement .vevent .summary a {
  color: #444;
}

.eventAggregatorElement .vevent .summary a:hover {
  color: #11104a;
}

.eventAggregatorElement .vevent .details {
  padding-top: 5px;
  padding-bottom: 12px;
}

.eventAggregatorElement .vevent .time, .eventAggregatorElement .vevent .location, .eventAggregatorElement .vevent .location:before {
  font-family: "Open Sans", sans-serif;
  font-size: 0.625rem;
  text-transform: uppercase;
  line-height: 1;
}

.eventAggregatorElement .vevent .time a {
  display: none;
}

.eventAggregatorElement .vevent .location:before {
  content: "/ ";
}

.eventAggregatorElement .tags, .eventAggregatorElement .description {
  display: none;
}

.eventAggregatorElement .condensed h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.eventAggregatorElement .condensed h4 a {
  color: #0086cb;
}

.eventAggregatorElement .condensed .dateAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 1;
  padding-top: 10px;
}

.eventAggregatorElement .expanded .newsItemElement h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.eventAggregatorElement .expanded .newsItemElement h2 a {
  color: #0086cb;
}

.eventAggregatorElement .expanded .newsItemElement h2 a:hover {
  text-decoration: none;
}

.eventAggregatorElement .expanded .newsDate, .eventAggregatorElement .expanded .newsAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 1;
  padding-top: 10px;
}

@media screen and (max-width: 290px) {
  .column .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 1em 0;
    position: relative;
  }
  .column .eventAggregatorElement .vevent .summary, .column .eventAggregatorElement .vevent .details {
    margin: 0;
    padding: 5px;
  }
  .column .eventAggregatorElement .vevent .details {
    padding: 0 5px 10px 5px;
  }
}

@media screen and (min-width: 651px) and (max-width: 770px) {
  .pe33 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 1em 0;
    position: relative;
  }
  .pe33 .eventAggregatorElement .vevent .summary, .pe33 .eventAggregatorElement .vevent .details {
    margin: 0;
    padding: 5px;
  }
  .pe33 .eventAggregatorElement .vevent .details {
    padding: 0 5px 10px 5px;
  }
}

@media screen and (min-width: 651px) {
  .threeColumn255025 .pe25 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 1em 0;
    position: relative;
  }
  .threeColumn255025 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn255025 .pe25 .eventAggregatorElement .vevent .details {
    margin: 0;
    padding: 5px;
  }
  .threeColumn255025 .pe25 .eventAggregatorElement .vevent .details {
    padding: 0 5px 10px 5px;
  }
}

@media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px) {
  .threeColumn502525 .pe25 .eventAggregatorElement .vevent .dateImage, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 1em 0;
    position: relative;
  }
  .threeColumn502525 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn502525 .pe25 .eventAggregatorElement .vevent .details, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .details {
    margin: 0;
    padding: 5px;
  }
  .threeColumn502525 .pe25 .eventAggregatorElement .vevent .details, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .details {
    padding: 0 5px 10px 5px;
  }
}

.aggregatorElement table {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.aggregatorElement .eventPreview p {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.aggregatorElement .eventPreview {
  text-align: left;
  width: 100%;
  margin: 0;
}

.aggregatorElement .eventPreview th {
  background: #DDD;
  word-break: break-word;
  text-align: center;
  line-height: 1;
}

.aggregatorElement .eventPreview th a {
  color: #233e94;
  font-weight: 600;
}

.aggregatorElement .eventPreview td {
  padding: 10px;
  background-image: none;
  background: transparent;
  word-break: break-word;
}

.aggregatorElement .eventPreview td:before {
  content: attr(data-week-view-date) "";
  display: none;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #999;
  background: #DDD;
  color: #233e94;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
}

.aggregatorElement .eventPreview td a {
  font-family: "Open Sans", sans-serif;
}

.aggregatorElement .eventPreview td,
.aggregatorElement .eventPreview th {
  border: none;
  border: 1px solid #999;
}

.aggregatorElement .eventPreview .event + .event {
  padding-top: 10px;
}

.aggregatorElement .eventPreview .noentry {
  word-break: normal;
}

.noEntry {
  border: none;
  font-size: 12px;
}

.aggregatorElement .eventPreview .event {
  border: none;
  padding: 0;
}

@media screen and (max-width: 450px) {
  .column .aggregatorElement .eventPreview,
  .column .aggregatorElement .eventPreview tbody,
  .column .aggregatorElement .eventPreview tr,
  .column .aggregatorElement .eventPreview td {
    display: block;
  }
  .column .aggregatorElement .eventPreview thead {
    display: none;
  }
  .column .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .column .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .column .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .column .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .column .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) {
  .pe33 .aggregatorElement .eventPreview,
  .pe33 .aggregatorElement .eventPreview tbody,
  .pe33 .aggregatorElement .eventPreview tr,
  .pe33 .aggregatorElement .eventPreview td {
    display: block;
  }
  .pe33 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .pe33 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .pe33 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .pe33 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .pe33 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .pe33 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) {
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 451px) {
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td, .threeColumn252550 .pe25 .aggregatorElement .eventPreview,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview thead, .threeColumn252550 .pe25 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td, .threeColumn252550 .pe25 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td:before, .threeColumn252550 .pe25 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview .event, .threeColumn252550 .pe25 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview .event + .event, .threeColumn252550 .pe25 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tbody, .threeColumn252550 .pe25 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) and (max-width: 850px) {
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tbody,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tr,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td, .twoColumn5050 .pe50 .aggregatorElement .eventPreview,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview tbody,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview tr,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview thead, .twoColumn5050 .pe50 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td, .twoColumn5050 .pe50 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td:before, .twoColumn5050 .pe50 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview .event, .twoColumn5050 .pe50 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview .event + .event, .twoColumn5050 .pe50 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tbody, .twoColumn5050 .pe50 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

#monthViewCalendar {
  margin: 0 15px 0 0;
}

#monthViewCalendar table, #monthViewCalendar tbody, #monthViewCalendar th, #monthViewCalendar tr, #monthViewCalendar td {
  display: block;
  background: 0;
}

#monthViewCalendar tr {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  flex: 0 0 auto;
  margin-right: -6px;
}

#monthViewCalendar th, #monthViewCalendar td {
  width: 1428.57143%;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 10px;
  background: #EFEFEF;
}

#monthViewCalendar th:before {
  font-size: 1rem;
  display: none;
}

@media screen and (max-width: 768px) {
  #monthViewCalendar th {
    font-size: 0;
  }
  #monthViewCalendar th:before {
    display: block;
  }
}

#monthViewCalendar th:nth-of-type(1):before {
  content: "Sun";
}

#monthViewCalendar th:nth-of-type(2):before {
  content: "Mon";
}

#monthViewCalendar th:nth-of-type(3):before {
  content: "Tue";
}

#monthViewCalendar th:nth-of-type(4):before {
  content: "Wed";
}

#monthViewCalendar th:nth-of-type(5):before {
  content: "Thu";
}

#monthViewCalendar th:nth-of-type(6):before {
  content: "Fri";
}

#monthViewCalendar th:nth-of-type(7):before {
  content: "Sat";
}

#monthViewCalendar td {
  min-height: 100px;
  height: auto;
  background: #FFF;
}

#monthViewCalendar caption {
  display: block;
  margin: 0 auto;
}

#monthViewCalendar .dateLink {
  text-decoration: none;
}

#monthViewCalendar .dateLink + .vevent {
  border-top: 1px solid #efefef;
}

#monthViewCalendar .today {
  background: #E4E4E4;
}

#monthViewCalendar .today a {
  color: #11104a;
}

#monthViewCalendar .today .dateLink + .vevent {
  border-top: 1px solid #11104a;
}

#monthViewCalendar .vevent {
  border: none;
  padding: 10px 0 0 0;
  margin: 10px 0;
}

@media screen and (max-width: 610px) {
  #monthViewCalendar tr {
    flex-flow: column;
  }
  #monthViewCalendar th {
    display: none;
  }
  #monthViewCalendar td {
    width: 100%;
    min-height: auto;
    text-align: left;
  }
  #monthViewCalendar .otherMonth {
    text-align: left;
  }
}

.calendar-page .contentTabs {
  height: auto;
}

.calendar-page .contentTabs li {
  float: none;
  line-height: 1;
  display: inline-block;
}

.calendar-page .contentTabs li span {
  display: inline-block;
}

.calendar-page .contentTabs li span a {
  display: inline-block;
}

.pageOptions {
  margin-right: 15px;
}

.pageEl:not(.link-callout) .linkElement h4 a {
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  background: 0;
  padding: 6px 10px;
  background-color: #c11e2d;
  color: #FFF;
}

.pageEl:not(.link-callout) .linkElement h4 a:hover {
  background-color: #11104a;
  color: #FFF;
}

.pageEl:not(.link-callout) .linkElement h4 a:after {
  content: " \203A";
}

.pageEl:not(.link-callout) .linkElement .text {
  margin: 0 0 10px 0;
  padding: 5px;
}

.alternate .pageEl:not(.link-callout) .linkElement h4 a {
  background-color: #11104a;
  color: #FFF;
}

.alternate .pageEl:not(.link-callout) .linkElement h4 a:hover {
  background-color: #c11e2d;
  color: #444;
}

/*
  Responsive Sport Header
  version: 1.0
  last modified: 11.3.2015 by Daniel Henderson
  author: Daniel Henderson
  email: dan.henderson@sportngin.com
  dependencies:
    1. Font Awesome https://fortawesome.github.io/Font-Awesome/
    2. sport_header.js
*/
@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-moz-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-o-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

#sport-header {
  float: left;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 0;
}

#sport-header .teamLogo {
  left: 15px;
  height: 40px;
  width: 120px;
}

#sport-header.noDrop #megaDropDown-seasons {
  padding-right: 10px !important;
}

#sport-header #snm_advert {
  display: inline-block;
  text-decoration: none;
  padding: 10px;
  background: transparent;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#sport-header #snm_advert:before {
  width: 30px;
  height: 25px;
  position: absolute;
  display: block;
  font: normal 14px/1em "Open Sans", sans-serif;
  font-size: 1.75em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  background: url("https://app-assets2.sportngin.com/app_images/marketing/mobilePhone.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#sport-header #snm_advert:hover {
  opacity: 1;
}

#sport-header #snm_advert span, #sport-header #snm_advert strong {
  color: #444;
  font-size: 9px;
  text-align: left;
  display: block;
  line-height: 13px;
  display: block;
  padding-left: 33px;
}

#sport-header .sport-tools {
  margin: 10px 0px 20px 0px;
  width: 100%;
  display: block;
  background: none;
  border-top: 1px solid #efefef;
  float: left;
  padding: 0px;
}

#sport-header .sport-tools ul {
  width: 100%;
  z-index: 1;
  position: relative;
}

#sport-header .sport-tools ul:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #efefef;
  float: left;
  z-index: -1;
  position: absolute;
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

#sport-header .sport-tools ul li {
  display: inline-block;
  margin: auto;
  background: none;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 3px solid #eee;
  float: left;
  list-style: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

#sport-header .sport-tools ul li a {
  display: inline-block;
  text-align: center;
  padding: 10px;
  background: none;
  border: none;
  list-style: none;
  cursor: pointer;
  color: #777;
  display: block;
  font: 1em/1.3em "Open Sans", sans-serif;
  outline: medium none;
  text-decoration: none;
  text-shadow: 1px 1px 1px #fff;
  width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

#sport-header .sport-tools ul li a:before {
  font: normal normal normal 14px/1em FontAwesome;
  padding: 0px;
  display: none;
}

#sport-header .sport-tools ul li:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 3px solid #11104a;
}

#sport-header .sport-tools ul li:hover a {
  color: #000;
}

#sport-header .sport-tools ul .selected {
  border-bottom: 3px solid #11104a;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}

#sport-header .sport-tools ul .selected:hover a {
  color: #11104a;
}

#sport-header .sport-tools ul .selected a {
  color: #11104a;
}

#sport-header .sport-tools ul #tool-home a span {
  background: none;
  text-indent: 0px;
  width: auto;
  height: auto;
}

#sport-header .sport-tools ul #tool-home a:before {
  content: "\f015";
}

#sport-header .sport-tools ul #tool-roster a:before {
  content: "\f007";
}

#sport-header .sport-tools ul #tool-game-schedule a:before {
  content: "\f133";
}

#sport-header .sport-tools ul #tool-player-stats a:before {
  content: "\f080";
}

#sport-header .sport-tools ul #tool-team-stats a:before {
  content: "\f1fe";
}

#sport-header .sport-tools ul #tool-standings a:before {
  content: "\f1b3";
}

#sport-header .sport-tools ul #tool-bracket a:before {
  content: "\f0e8";
}

#sport-header .sport-tools ul #tool-photo a:before {
  content: "\f03e";
}

#sport-header .sport-tools ul #tool-video a:before {
  content: "\f1c8";
}

#sport-header .sport-tools ul #tool-posts a:before {
  content: "\f086";
}

#sport-header .sport-tools ul .tool-yellow-admin a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow-manage-team a:before {
  content: "\f024";
}

#sport-header .sport-tools ul.tools {
  width: 100%;
}

#sport-header .sport-tools .button-tool {
  float: left;
}

#sport-header .sport-tools li, #sport-header .sport-tools #autoRefreshBar li {
  display: inline;
  float: left;
  border-right: 1px solid #eee;
}

#sport-header .sport-tools li.tool-yellow {
  border-color: #edc52c;
}

#sport-header .sport-tools li.tool-yellow a {
  background: #fddd40 url("https://app-assets2.sportngin.com/app_images/sport-header/btnsBG-yl.gif") 0 0 repeat-x;
  border-color: #ffc;
  color: #807540;
}

#sport-header .sport-tools li.tool-yellow:hover {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:hover a {
  background-color: #fddd40;
  background-position: 0 -80px;
  border-color: #ffc;
  color: #1a1a1a;
}

#sport-header .sport-tools li.tool-yellow:active {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:active a {
  background-color: #fddd40;
  background-position: 0 -160px;
  color: #333;
}

#sport-crumbs {
  background: #fff !important;
  position: relative;
  float: left;
  display: block;
  width: 100%;
}

#sport-crumbs .sport-crumbs-btns {
  display: block;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

#sport-crumbs .sport-crumbs-btns::-webkit-scrollbar {
  display: none;
}

#sport-crumbs ul {
  display: inline-block;
  float: left;
}

#sport-crumbs ul li {
  float: left;
  list-style: none;
}

#sport-crumbs ul li a {
  padding: 0px;
}

#sport-crumbs ul li a:before {
  content: "\f054";
  color: #ccc;
  display: inline-block;
  font: normal 10px/1em FontAwesome;
  padding: 2px;
  position: relative;
  margin: 0 0 0 10px;
}

#sport-crumbs ul li:first-child a:before {
  display: none;
}

#sport-crumbs ul li.crumbSelected {
  position: relative;
}

#sport-crumbs ul li.crumbSelected a {
  color: #000;
}

#sport-crumbs ul li.crumbSelected .arrow-down:after {
  content: "\f077";
  color: #000;
}

#sport-crumbs .sport-crumbs-list ul li a {
  padding: 10px 20px;
}

#sport-crumbs a {
  color: #666666;
  display: block;
  outline: medium none;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  font-weight: normal;
  background: none;
}

#sport-crumbs a.last_child {
  background: none;
}

#sport-crumbs a.last_child:hover {
  background: none;
}

#sport-crumbs a span {
  display: inline-block;
  font-size: 0.85em;
  padding: 10px;
  text-align: center;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sport-crumbs a span:after {
  border: 1px solid #777;
  border-radius: 20px;
  font: 12px/1em FontAwesome;
  margin: auto;
  padding: 4px;
  text-align: center;
}

#sport-crumbs a span.division, #sport-crumbs a span.league, #sport-crumbs a span.team {
  background: none;
  padding: 5px 0 5px 5px;
}

#sport-crumbs a .arrow-down {
  background: none;
  display: block;
  height: auto;
  margin: auto;
  opacity: 0.75;
  padding: 0;
  position: relative;
  right: 0px;
  top: 50%;
  width: auto;
  display: inline-block;
  padding-left: 10px;
}

#sport-crumbs a:hover {
  color: #000;
  background: none;
}

#sport-crumbs a.wide {
  padding: 0px;
}

#sport-crumbs .arrow-down:after {
  content: "\f078";
  display: inline-block;
}

#sport-crumbs .sport-crumbs-list-wrapper {
  display: block;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list {
  display: none;
  text-align: left;
  float: left;
  margin: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  max-width: 50%;
  z-index: 20;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

#sport-crumbs .sport-crumbs-list.thisCrumb {
  display: block !important;
  -o-animation: dropdown-season 0.2s linear;
  -moz-animation: dropdown-season 0.2s linear;
  -webkit-animation: dropdown-season 0.2s linear;
  animation: dropdown-season 0.2s linear;
}

#sport-crumbs .sport-crumbs-list ul {
  list-style: none;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list ul li {
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a {
  background: #fff;
  color: #666;
  border-bottom: 1px solid #eee;
  display: block;
  font-size: 12px;
  line-height: 24px;
  padding: 5px 10px;
  text-decoration: none;
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a:before {
  display: none;
}

#sport-crumbs .sport-crumbs-list ul li a img {
  margin: 0 3px -3px 0;
}

#sport-crumbs .sport-crumbs-list ul li a:hover {
  color: #000;
  background-color: #fafafa;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close {
  display: block;
  background: #fff;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a {
  display: block;
  float: right;
  text-align: right;
  width: 50px;
  border-bottom: 0px;
  background: transparent;
  padding: 15px 15px 10px 10px;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after {
  content: "\f00d";
  position: relative;
  font: normal normal normal 12px/1em FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 40px;
  padding: 3px 5px;
  border: 1px solid #666;
  color: #666;
  opacity: 0.7;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after:hover {
  color: #000;
  border-color: #000;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:hover {
  background: #fff;
  color: #000;
}

.sport-colors {
  clear: both;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.sport-colors .edge {
  display: none;
}

.sport-colors h2 {
  float: left;
  font: bold 2.3em/1.5em "Open Sans", sans-serif;
  max-width: 75%;
  padding: 15px 0px 15px 0px;
  position: relative;
  text-shadow: 1px 1px 0 #fff;
  width: 100%;
  left: 0;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
}

.sport-colors h2 a {
  display: inline-block;
  text-decoration: none;
  padding: 0px;
}

.sport-colors .teamLogo-large {
  height: 75px !important;
  width: 75px !important;
  position: relative;
  display: inline-block;
  float: left;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 3px solid #ddd;
  left: 0;
  top: 0;
  margin: 15px;
  background-size: contain;
}

/* Seasons Mega Drop Down */
.dropdown-wrapper {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  z-index: 2;
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons:after {
  content: "\f077";
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons-callout {
  display: block;
  -o-animation: dropdown-season 0.2s linear;
  -moz-animation: dropdown-season 0.2s linear;
  -webkit-animation: dropdown-season 0.2s linear;
  animation: dropdown-season 0.2s linear;
}

.dropdown-wrapper #megaDropDown-seasons {
  background: #efefef;
  display: block;
  margin: auto;
  outline: medium none;
  text-align: right;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 50px 10px 10px !important;
  background-image: none !important;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.dropdown-wrapper #megaDropDown-seasons .season {
  color: #999;
  display: block;
  font: normal 10px/1em "Open Sans", sans-serif;
  margin: auto;
  width: auto;
}

.dropdown-wrapper #megaDropDown-seasons .subseason {
  color: #666;
  font: bold 12px/1em "Open Sans", sans-serif;
}

.dropdown-wrapper #megaDropDown-seasons:after {
  content: "\f078";
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 100px;
  border: 1px solid #666;
  color: #666;
  font: normal 12px/1em FontAwesome;
  padding: 4px;
}

.dropdown-wrapper #megaDropDown-seasons-callout {
  background: #fff;
  padding: 10px;
  text-align: left;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 230px;
  z-index: 2;
  padding: 15px 15px 20px 15px;
  display: none;
  border: 2px solid #eee;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title {
  display: block;
  font: bold 12px "Open Sans", sans-serif;
  padding: 0 0 5px;
  margin: 5px 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title span {
  float: left;
}

.dropdown-wrapper #megaDropDown-seasons-callout select {
  width: 100%;
  border: 0px;
  border: 2px solid #eee;
  background: #fff;
  color: #000;
  background-image: url("https://app-assets2.sportngin.com/app_images/select-down-arrow.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 27px;
  padding: 7px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown-wrapper #megaDropDown-seasons-callout a {
  display: block;
  font-size: 12px;
  padding: 2px 5px;
  text-decoration: none;
  background-color: #efefef;
  text-align: center;
  color: #666;
  border: 1px solid #fff;
}

.dropdown-wrapper #megaDropDown-seasons-callout a:hover {
  background: #efefef;
  border: 1px solid #e6e6e6;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.close {
  background: #fff;
  border: 1px solid #777;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #777;
  display: block;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0px 7px 2px 7px;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.current {
  background: #efefef none repeat scroll 0 0;
  border-radius: 0;
  color: #333;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
}

.dropdown-wrapper #megaDropDown-seasons-callout .footer {
  margin: 10px 0 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout optgroup option {
  margin: 0;
  padding: 0 0 0 10px;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active {
  cursor: pointer;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:after {
  display: block;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:hover {
  background-color: #efefef;
}

.teamPage #rapidTabs.rapidTabsConstruction {
  background: none;
}

.yieldPageContent {
  clear: both;
}

@media only screen and (max-width: 1025px) {
  #sport-header .sport-tools ul li a {
    font-size: .9em;
  }
}

@media only screen and (max-width: 769px) {
  #sport-header #sport-crumbs ul li.crumbSelected {
    position: static;
  }
  #sport-header #sport-crumbs .sport-crumbs-list {
    max-width: 100%;
  }
  #sport-header #sport-crumbs a span {
    font-size: 0.70em;
  }
  #sport-header #sport-crumbs a span:after {
    font: 10px/1em FontAwesome;
  }
  #sport-header #sport-crumbs a .arrow-down {
    margin-left: 0px;
  }
  #sport-header .sport-tools ul li a {
    padding: 10px 2px;
    font-size: 0em;
  }
  #sport-header .sport-tools ul li a:before {
    display: block;
  }
  /* Seasons Mega Drop Down */
  .dropdown-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .dropdown-wrapper:before {
    display: none;
  }
  .dropdown-wrapper #megaDropDown-seasons .season {
    font-size: 10px;
  }
  .dropdown-wrapper #megaDropDown-seasons .subseason {
    font-size: 9px;
  }
  .dropdown-wrapper #megaDropDown-seasons-callout {
    max-width: 100%;
  }
}

.abs0 {
  display: none;
}

.sport-colors .teamLogo-large {
  border: none;
  border-radius: none;
  min-width: 75px;
}

@media screen and (max-width: 300px) {
  .sport-colors .teamLogo-large {
    display: none;
  }
}

.sport-colors {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  float: none;
}

.sport-colors h2 {
  margin-right: auto;
}

.widgetHierarchy li {
  background: none;
  border: none;
}

.statTable {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.statTable .odd {
  background-color: transparent;
}

.statTable tbody {
  background: #FFF;
}

.statTable tbody tr {
  border-bottom: 2px solid #444;
}

.statTable tr th {
  background: #333;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.statTable .ajax_sort:hover {
  color: #11104a;
}

.statTable td, .statTable th {
  font-size: 16px;
  line-height: 1;
  padding: 10px;
  border: none;
}

.statTable .hightlight {
  background: #999;
}

.statTable .teamName, .statTable .teamName-tiny {
  font-weight: 300;
}

.hrElement {
  border-bottom: 1px solid #cfb051;
}

.layoutContainer .hrElement {
  margin-right: 15px;
}

.full-width {
  margin-right: 0;
  padding-left: 0;
}

.full-width-container {
  margin-right: 0;
  padding: 3vw;
}

.full-width-container.oneColumn .pageElement {
  margin-right: 0;
}

.user_mode .background-image {
  display: none;
}

.edit_mode .background-image:before {
  max-width: 300px;
  margin: 0 auto;
  display: block;
  padding: 1em;
  text-align: center;
  content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
}

.edit_mode .background-image img {
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid #FFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

#home .layoutContainer .pageElement h3 span {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  color: #11104a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#home .layoutContainer .pageElement h3 span b {
  font-weight: 900;
  color: #c11e2d;
}

#home .yieldPageContent {
  padding-top: 0;
}

#home #yieldContent, #home #panelTwo {
  background: 0 !important;
}

#home .layoutContainer:not(.full-width) {
  background-color: #FFF;
  margin-bottom: 35px;
}

#home .layoutContainer:not(.full-width) .pageElement {
  margin: 0 0 20px 0;
}

.user_mode#home .layoutContainer:not(.full-width) {
  position: relative;
  border-bottom: 2px solid #c11e2d;
  box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.8);
  padding: 25px 0px 10px 25px;
}

.user_mode#home .layoutContainer:not(.full-width):after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c11e2d;
}

.user_mode#home .layoutContainer:not(.full-width) .pageElement {
  margin: 0 25px 20px 0;
}

.link-callout {
  text-align: center;
}

.link-callout .linkElement {
  background-size: cover;
  background-position: center;
}

.link-callout.red .linkElement {
  background-color: #c11e2d;
  background-image: linear-gradient(to top, #c11e2d 0%, rgba(193, 30, 45, 0.3) 100%), url(../images/baseball.png);
}

.link-callout.blue .linkElement {
  background-color: #11104a;
  background-image: linear-gradient(to top, #11104a 0%, rgba(17, 16, 74, 0.3) 100%), url(../images/baseball.png);
}

.link-callout.left .icon {
  text-align: left;
  justify-content: flex-start;
}

@media screen and (max-width: 650px) {
  .link-callout.left .icon {
    justify-content: center;
    text-align: center;
  }
}

.link-callout.right .icon {
  text-align: right;
  justify-content: flex-end;
}

@media screen and (max-width: 650px) {
  .link-callout.right .icon {
    justify-content: center;
    text-align: center;
  }
}

.link-callout h4 {
  position: relative;
  min-height: 106px;
  margin: 0;
}

.link-callout h4:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #cfb051;
  position: absolute;
  bottom: 10px;
  left: 0px;
}

.link-callout .icon {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  line-height: 1.2;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFF;
  background: 0;
  padding: 20px;
  width: 100%;
  min-height: 106px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.flex-full, .flex-full-edit {
  min-width: 100%;
  flex-basis: 100% !important;
}

.user_mode .stay-connected .column {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.user_mode .stay-connected .column > div {
  flex-basis: 31%;
}

.edit_mode .stay-connected .columnBounds {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.edit_mode .stay-connected .columnBounds > .addPageElementTop {
  min-width: 100%;
}

.edit_mode .stay-connected .columnBounds > div {
  flex-basis: 31%;
}

.stay-connected .drag:first-of-type {
  min-width: 100%;
}

.stay-connected .pageElementAdmin .codeElement pre {
  display: none;
}

@media screen and (max-width: 750px) {
  .stay-connected .column, .stay-connected .columnBounds {
    display: block;
  }
  .stay-connected .column > div, .stay-connected .columnBounds > div {
    min-width: 100%;
    margin: 0;
  }
}

.stay-connected .home-facebook {
  margin: 0 25px;
}

.stay-connected .home-facebook > div > div,
.stay-connected .home-facebook > div > div > span,
.stay-connected .home-facebook > div > div > span > iframe {
  width: 100% !important;
  max-width: 100% !important;
}

.home-tweets .pageElement:before, .home-facebook .pageElement:before, .home-instagram .pageElement:before {
  font-family: FontAwesome;
  font-size: 28px;
  text-rendering: auto;
  text-align: center;
  text-decoration: none;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c11e2d;
  margin: 0 auto 10px auto;
  line-height: 1;
}

.home-tweets .pageElement:before {
  content: "\f099";
}

.home-facebook .pageElement:before {
  content: "\f230";
}

.home-instagram .pageElement:before {
  content: "\f16d";
}

.centered img {
  margin: 0 auto;
}

.contain img {
  width: auto;
  max-width: 100%;
}

.user_mode#home .merge-top {
  margin-top: -42px;
  box-shadow: 0px 10px 10px -12px rgba(0, 0, 0, 0.8) !important;
}

@media screen and (min-width: 769px) {
  .noUserBar #siteHeader {
    background: #11104a;
    border-bottom: 1px solid #cfb051;
    height: 30px;
  }
}

.user_mode .home-sponsor .column {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.user_mode .home-sponsor .column > div {
  flex-basis: 18%;
}

.edit_mode .home-sponsor .columnBounds {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.edit_mode .home-sponsor .columnBounds > .addPageElementTop {
  min-width: 100%;
}

.edit_mode .home-sponsor .columnBounds > div {
  flex-basis: 18%;
}

.home-sponsor .column .pageEl:first-of-type {
  min-width: 100%;
}

.home-sponsor .drag:first-of-type {
  min-width: 100%;
}

.user_mode .home-sponsor .column > div {
  flex: 1 1 auto;
}

.home-sponsor .heroPhotoElement {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.home-sponsor .heroPhotoElement img {
  width: auto !important;
  display: block;
  max-height: 60px;
  max-width: 100%;
  margin: 0 auto;
}

#navLinksContainer {
  top: 25px;
}
