$bar-thickness: 3px;                    // The thickness of the button bars
$button-width: 50px;                    // The width of the button area
$button-height: 40px;                   // The height of the button area
$button-padding: 10px;
$button-bar-space: 6px;      // The spacing between button bars
$button-transistion-duration: 0.1s;     // The transition duration
$button-color: $white-color;
$button-background-color: $primary-color;

$nav-item-border-size: 1px;

.mobileNav{
  display: none;
  @include respond-to(max, md){
    display: block;
  }
}
#topNav{
  @include respond-to(max, md){
    display: none;
  }
}
.site{
  @include respond-to(max, md){
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #FFF;
    height: 60px;
    background-color: $primary-color;
    a{
      display: inline-block;
      height: 50px;
      width: 60px;
      background-image: url(../images/site-logo-bg.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
    }
  }
}

.navButton {
  display: none;
  @include respond-to(max, md){
    display: block;
  }
  position: relative;
  overflow: hidden;
  margin: 0 0 0 10px;
  // margin-right: auto; // Used for flexbox of topNav
  padding: $button-padding;
  width: $button-width;
  height: $button-height;
  // font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background $button-transistion-duration ease-in-out;
  @extend %clearfix;
}

.navButton:focus {
  outline: none;
}

.navButton span {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $button-padding;
  right: $button-padding;
  height: $bar-thickness;
  background: $button-color;
}

.navButton span::before,
.navButton span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: $bar-thickness;
  background-color: $button-color;
  content: "";
}

.navButton span::before {
  top: -$bar-thickness - $button-bar-space ;
}

.navButton span::after {
  bottom: -$bar-thickness - $button-bar-space ;
}

//

.navButton {
  background-color: $button-background-color;
}

.navButton span {
  transition: background 0s $button-transistion-duration;
}

.navButton span::before,
.navButton span::after {
  transition-duration: $button-transistion-duration, $button-transistion-duration;
  transition-delay: $button-transistion-duration, 0s;
}

.navButton span::before {
  transition-property: top, transform;
}

.navButton span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */



.navButton.expanded span {
  background: none;
}

.navButton.expanded span::before {
  top: 0;
  transform: rotate(45deg);
}

.navButton.expanded span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.navButton.expanded span::before,
.navButton.expanded span::after {
  transition-delay: 0s, $button-transistion-duration;
}


/* Styling */
.mobileNav{
// Main Toggle
  display: none;
  &.expanded{
    @include respond-to(max, md){
      display: block;
    }
  }
// Dropdown Levels
  li{ // Level 1
    text-align: left;
    background-color: $primary-color;
    border-bottom: $nav-item-border-size solid $primary-color-dk;
    transition: color .2s linear, background-color .2s linear;
    a{
      color: $white-color;
      // background-color: $dark-color;
      // border-bottom: 2px solid $white-color;
      transition: color .2s linear, background-color .2s linear;
    }
  }
  .dropdown.expanded{
    border-top: $nav-item-border-size solid $primary-color-md;
  }
  li.expanded, li.expanded li.expanded{
    border: none;
  }
  .lastNavElement .dropdown > li.expanded:last-of-type{padding: 0;}
  .dropdown li{ // Level 2
    background-color: #222;
    border-bottom: none;
    // &:first-of-type{padding-top:10px;}
    &:last-of-type{
      // padding-bottom:10px;
      border-bottom: $nav-item-border-size solid $primary-color-md;
    }

    a{
      color: $white-color;
      // background-color: $light-color;
      // border-bottom: 2px solid $dark-color;
    }
    li{ // Level 3
      background-color: $white-color;
      border-bottom: none;
      a{
        color: $primary-color;
        // background-color: darken($light-color, 10%);
        // border-bottom: 2px solid $dark-color;
      }
    }
    ul{
      border-top: $nav-item-border-size solid $dark-color;
    }
  }
// Hover and selected states
  li{
    &:hover, &.selected{
      background-color: $primary-color-dk;
    }
    li{
      &:hover, &.selected{
        background-color: $primary-color-dk;
      }
      li{
        &:hover, &.selected{
          background-color: $white-color;
        }
      }
    }
  }
  li a{
    &:hover, &.selected{
      color: $white-color;
    }
  }
  li li a{
    &:hover, &.selected{
      color: $secondary-color;
    }
  }
// General Links
  a, .nodeToggle{
    display: inline-block;
    font-family: $secondary-font;
    font-size: rem(16px);
    font-weight: 300;
    // text-transform: uppercase;
    letter-spacing: 1px;
    // @include font-smooth();
    line-height: 1;
    padding: 10px;
    text-decoration: none;
  }
  .dropdown a{
    display: inline-block;
    // color: $primary-color;
    transition: color .2s linear;
    font-family: $quaternary-font;
    font-size: rem(14px);
    font-weight: 600;
    line-height: 1.2;
    @include font-smooth;
    text-transform: none;
    letter-spacing: 0;
    padding: 10px 0 10px 20px;

  }
  .dropdown ul a{
    margin-left: 20px;
  }

// Remove Extra
  .sn-tag-logo, .site-logo{display: none;}
}


.nodeToggle{
  cursor: pointer;
  display: inline-block;
  padding-left: 0;
  &:before{
    border-color: $white-color;
    li li &{
      border-color: $white-color;
    }
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 8px;
    left: 0;
    position: relative;
    top: 4px;
    transform: rotate(45deg);
    vertical-align: top;
    width: 8px;
    transition: transform .1s linear;
  }
  &.expanded:before{
    transform: rotate(135deg);
  }
  + ul{
    display: none;
  }
}


// topNav treatment
@include respond-to(max, md){
  .site-header{
    margin: 0;
    padding: 10px 10px 20px 10px;
    max-width: 100%;
    position: relative;
    top: auto;
    left:auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    a{
      // height: 50px;
    }
  }
  .siteContainer:before{
    height: 150px;
  }
}
