$event-icon-width: 50px;
$event-icon-height: 55px;
$event-icon-month: 14px;
$event-icon-day: 20px;
$event-even: #f3f3f3;
$event-odd: #fafafa;


.eventAggregatorElement{
  .even, .odd{
    background-color: transparent;
  }
  a{
    text-decoration: none;
  }
  .aggHeader{
    @extend %agg-headers;
  }
  .vevent{
    line-height: 1.2;
    border: none;
    // border-bottom: 2px solid #EEE;
    padding: 0 0 0 0;
    min-height: $event-icon-height;

    + .vevent{
      padding-top: 5px;
      min-height: $event-icon-height;
    }
    &.odd{
      background: 0;
      .summary, .details, .dateImage{background-color:$event-odd;}
    }
    &.even{
      background: 0;
      .summary, .details, .dateImage{background-color:$event-even;}
    }
    .dateImage{
      width: $event-icon-width;
      padding: 10px 5px;

      .month{
        font-size: rem($event-icon-month);
        font-weight: 400;
        line-height: 1.5;
        color: $tertiary-color;
        text-transform: uppercase;
      }
      .date{
        font-size: rem($event-icon-day);
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        color: $tertiary-color;
        // letter-spacing: -3px;
      }
    }
    .summary, .details{
      margin-left: $event-icon-width + 5px;
      padding: 10px;
    }
    .summary{
      font-family: $primary-font;
      font-size: rem(18px);
      font-weight: 400;
      line-height: 1.2;
      padding-bottom: 0;
      a{
        color: $dark-color;
        &:hover{
          color: $primary-color;
        }
      }
    }
    .details{
      padding-top: 5px;
      padding-bottom: 12px;
    }
    .time, .location, .location:before{
      font-family: $primary-font;
      font-size: rem(10px);
      // font-style: italic;
      text-transform: uppercase;
      line-height: 1;
    }
    .time{
      a{display: none;}
    }
    .location{
      &:before{
        content: "/ ";
      }
    }
  }
  .tags, .description{
    display: none;
  }
}
.eventAggregatorElement{
  .condensed{
    h4{
      font-family: $primary-font;
      font-size: rem(18px);
      line-height: 1.5;
      a{
        color: $primary-color-md;
      }
    }
    .dateAuthor{
      font-family: $primary-font;
      font-size: rem(12px);
      font-style: italic;
      line-height: 1;
      padding-top: 10px;
    }
  }
  .expanded{
    .newsItemElement{
      h2{
        font-family: $primary-font;
        font-size: rem(18px);
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        a{
          color: $primary-color-md;
          &:hover{text-decoration: none;}
        }
      }
    }
    .newsDate, .newsAuthor{
      font-family: $primary-font;
      font-size: rem(12px);
      font-style: italic;
      line-height: 1;
      padding-top: 10px;
    }
  }
}


@mixin sm-event-aggregator{
  // .eventAggregatorElement{
    .vevent .dateImage {
      width: 100%;
      padding: 1em 0;
      position: relative;
    }
    .vevent {
      .summary, .details {
        margin: 0;
        padding: 5px;
      }
      .details{
        padding: 0 5px 10px 5px;
      }
    }
  // }
}



.eventAggregatorElement{
  .column &{
    @include respond-to(max, 290px){
      @include sm-event-aggregator;
    }
  }
  .pe33 &{
    @media screen and (min-width: 651px) and (max-width: 770px){
      @include sm-event-aggregator;
    }
  }
  .threeColumn255025 .pe25 &{
    @include respond-to(min, 651px){
      @include sm-event-aggregator;
    }
  }
  .threeColumn502525 .pe25 &, .threeColumn252550 .pe25 &{
    @media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px){
      @include sm-event-aggregator;
    }
  }
  // .twoColumn5050 .pe50 &{
  //   @media screen and (min-width: 576px) and (max-width: 875px){
  //     @include sm-event-aggregator;
  //   }
  // }
}
