// Optimized only for full width column
$control-height: 30px;
$borderColor: #FFF;

.home-slider{
  .newsSlideShow{
    margin: 0;
  }
}

.newsSlideShow-article{
  display: flex;
  align-items: flex-end;
  padding: 0;
  height: calc(100% - #{$control-height});
  .newsSlideShow-headline{
    background: rgba($primary-color, .9);
    position: relative !important;
    z-index:1;
    bottom: auto;
    color:inherit;
    padding: 20px;
    width:100%;
    border-bottom: 2px solid $secondary-color;
    h1{
      font-family: $secondary-font;
      font-size: 36px;
      font-weight: 300;
      line-height: 1.2;
      display: block;
      padding: 0;
      max-width: $max-content-width;
      width: 100%;
      margin: 0 auto;
      @include respond-to(max, 300px){
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      a{ height: auto; }
    }
    p{ display: none; }
  }
  img{
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%);
  }

}



// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }


.newsSlideShow-container {
  background: none;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: $control-height;
  // 2. Thumbnails
  .newsSlideShow-thumbnails {
    position: absolute;
    z-index: 10;
    padding:0;
    left: 50%;
    bottom: 1px;
    width: 100%; // Subtract width of left/right arrows
    height: $control-height;
    list-style-type: none;
    line-height: 1;
    text-align: center;
    // cursor: default;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    li {
      display: inline-block;
      width: 100px;
      margin: 0 5px;
      cursor: pointer;
    }

    a {
      opacity: 1;
      width: 100%;
      height: 10px;
      display: block;
      font-size: 1.25em;
      border: 4px solid $secondary-color;
      background: $tertiary-color;
      &:hover,
      &.current {
        background: $secondary-color;
        border: 4px solid $secondary-color;
      }
    }

    img {
      display: none;
      width: 100%;
    }
  }

  // 3. Controls
  .newsSlideShow-controls {
    max-width: $max-content-width;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .newsSlideShow-prev,
  .newsSlideShow-next{
    text-shadow: none;
    &:after{
      font-size: 45px;
    }
  }

  .newsSlideShow-prev{
    &.pill-small-left{
      &:after{
        content: "\2039";
      }
    }
  }
  .newsSlideShow-next{
    &.pill-small-right{
      &:after{
        content: "\203A";
      }
    }
  }


  .newsSlideShow-prev.pill-small-left,
  .newsSlideShow-next.pill-small-right {
    position: relative;
    z-index: 10;
    bottom: auto;
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    box-shadow: 0;
    font-size: 0;
    text-align: center;
    color: $tertiary-color;

    // &:hover {
    //   color: $tertiary-color;
    //   background-color: transparent;
    //
    //   &:after {
    //     // transform: scale(1.375);
    //   }
    // }

    &:after {
      line-height: 1;
      text-indent: 0;
      top: 50%;
      transform: translateY(-50%);
      // width: 100%;
      height: 100%;
      padding: 0;
      display: block;
      color: inherit;
      font-family: 'fontawesome';
      transition:
        transform .1s ease-in-out,
        color .1s ease-in-out;
    }
  }

}
